import { FC } from 'react'

export interface Error404Props {
  className?: string | undefined
  [x: string]: any
}

export const Error404: FC<Error404Props> = ({ children }: Error404Props) => {
  return <div className='flex h-full w-full'>404 page</div>
}

Error404.defaultProps = {
  className: undefined,
}

export default Error404
