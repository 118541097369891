export const createImage = (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues
    image.src = url
  })

export function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: any, height: any, rotation: any) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  isRectangleLogo: any,
  imageSrc: any,
  fileName: any,
  pixelCrop: any,
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx: any = canvas.getContext('2d')

  ctx.fillStyle = 'rgb(0,0,0,0)'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg')

  // const dataUrl = canvas.toDataURL(`image/png`)

  // const base64String = dataUrl.split(',')[1]

  // // Decode the base64 string into binary data (Uint8Array)
  // const binaryData = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))

  // return new File([binaryData], `abc.png`, {
  //   type: `image/png`,
  // })

  const dataUrl = canvas.toDataURL(`image/png`)

  const base64String = dataUrl.split(',')[1]

  // Decode the base64 string into binary data (Uint8Array)
  const binaryData = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))

  return new File([binaryData], `${fileName}`, {
    type: `image/png`,
  })
}
