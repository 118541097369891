import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

interface UploadClientProfilePictureInterface {
  Id: string
  avatarObject: string
}

export const useUploadClientProfilePicture = () => {
  const mutate = useMutation(
    ({ Id, avatarObject }: UploadClientProfilePictureInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Avatar', avatarObject)

      return post(`/ClientProfile/${Id}/Avatar`, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  )

  return mutate
}

export default useUploadClientProfilePicture
