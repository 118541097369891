/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import defaultPic from 'assets/images/default-pic.svg'
import CN from 'classnames'
import { motion } from 'framer-motion'
import { setLogoutModalShow } from 'store/reducers/layout/layoutSlice'
import {
  setCurrentRoute as acSetCurrentRoute,
  setIsExpanded,
  setMenu as acSetMenu,
} from 'store/reducers/side-menu/mainSideMenuReducer'

import MenuItem from 'components/common/Sidebar/MenuItem'

interface SidebarProps {
  className: string
}

/** this is the main components of main side bar */
export const MainSideBar: FC<SidebarProps> = ({ className }: SidebarProps) => {
  const navigate = useNavigate()
  const { menuItems, currentRoute, isExpanded, initialMenu } = useSelector(
    (state: any) => state.sideMenuList,
  )

  const { userDetails }: any = useSelector<any>((state) => state.user)

  const [menuKey, setMenuKey] = useState(Math.random())

  const dispatch: any = useDispatch()
  const location = useLocation()

  /** handle current route */
  const setCurrentRoute = () => {
    const { pathname } = location
    dispatch(acSetCurrentRoute(pathname))
  }

  /** dispatch current route when select new tab */
  useEffect(() => {
    setCurrentRoute()
  }, [location])

  /** handle active tab when page reload */
  useEffect(() => {
    const { pathname } = location
    const firstRoute = pathname.split('/')[1]
    const secondRoute = pathname.split('/')[2]

    if (menuItems.length > 0) {
      const itemsToUpdate = menuItems?.map((menuItem: any) => {
        return {
          ...menuItem,
          subMenu: menuItem?.subMenu?.map((subMenuItem: any) => {
            return {
              ...subMenuItem,
              isActive: false,
              subMenu: subMenuItem?.subMenu?.map((innerMenuItem: any) => {
                return { ...innerMenuItem, isActive: false }
              }),
            }
          }),
          isActive: false,
        }
      })
      const cloneItemsToUpdate = JSON.parse(JSON.stringify(itemsToUpdate))

      // First Menu
      if (
        firstRoute ||
        `${firstRoute}` === '' ||
        firstRoute === '/' ||
        firstRoute === ' '
      ) {
        const selectedIndex = cloneItemsToUpdate?.findIndex((item: any) => {
          return item.link === `/${firstRoute}`
        })
        if (selectedIndex > -1) {
          cloneItemsToUpdate[selectedIndex].isActive = true
          // Second Menu .....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (secondRoute) {
            const selectedSubMenuIndex = cloneItemsToUpdate[
              selectedIndex
            ]?.subMenu.findIndex((subItem: any) => {
              return subItem.link === `/${firstRoute}/${secondRoute}`
            })

            if (selectedIndex > -1 && selectedSubMenuIndex > -1) {
              cloneItemsToUpdate[selectedIndex].isTempActive = true
              cloneItemsToUpdate[selectedIndex].subMenu[
                selectedSubMenuIndex
              ].isActive = true
            }
          }
          // .....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

          dispatch(acSetMenu(cloneItemsToUpdate.slice()))
        }
      }
    }
    setMenuKey(Math.random())
  }, [currentRoute])

  /** side bar main styles */
  const SidebarClasses = CN(
    `sidebar flex flex-col h-full flex-shrink-0 bg-white shadow relative`,
    className,
  )

  const handleLogoutClick = () => {
    dispatch(setLogoutModalShow())
  }

  return (
    <div className={SidebarClasses}>
      <motion.nav
        className={CN(
          'flex flex-col w-full relative py-3 px-3 h-[calc(100%-72px)] justify-between border-r-[1px] border-N-200',
          {
            'min-w-[300px] items-start': isExpanded,
            'min-w-[88px] items-center': !isExpanded,
          },
        )}
        key={menuKey}
        initial={{ width: isExpanded ? 300 : 88 }} // Initial width based on isExpand state
        animate={{ width: isExpanded ? 300 : 88 }} // Target width when expanded or collapsed
        exit={{ width: 0 }} // Animation when unmounting
        transition={{ duration: 0.3 }} // Animation duration
      >
        <div
          className='absolute flex w-[24px] h-[24px] bg-white hover:bg-N-100 border border-N-300 p-1 rounded-[7px] justify-center items-center right-0 cursor-pointer mt-2.5 -mr-[12px] z-[10]'
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setIsExpanded(!isExpanded))
          }}>
          <i
            className={CN('text-[16px] leading-none text-N-500', {
              'ri-arrow-left-s-line': isExpanded,
              'ri-arrow-right-s-line': !isExpanded,
            })}
          />
        </div>
        <div className='flex flex-col w-full gap-y-3 px-[10px]'>
          {menuItems.map((mainMenuitem: any, idx: number) => (
            <MenuItem
              menuItems={menuItems}
              item={mainMenuitem}
              key={idx}
              index={idx}
              currentRoute={currentRoute}
              isExpanded={isExpanded}
            />
          ))}
        </div>
        <div className='bottom-0 w-full flex flex-col gap-y-3'>
          <div
            className={CN(
              ' flex justify-center p-3 items-center rounded-md h-fit cursor-pointer w-full border font-semibold group',
              {
                'bg-P-100 text-P-600 border-P-500 ': currentRoute
                  ?.split('/')[1]
                  ?.includes('profile'),
                'bg-white text-N-700 hover:bg-P-50 border-N-300 hover:border-P-400':
                  !currentRoute?.split('/')[1]?.includes('profile'),
              },
            )}
            onClick={() => {
              dispatch(acSetMenu(initialMenu))
              navigate('/profile?tab=my-profile')
            }}>
            <img
              src={userDetails?.avatarUrl || defaultPic}
              alt='userprofile'
              className='w-10 object-fill border border-N-200 rounded-full'
            />

            {isExpanded && (
              <div className='flex justify-between items-center ml-2 w-full'>
                <div className='flex flex-col'>
                  <span className='text-small text-N-800 font-medium'>
                    {userDetails?.firstName} {userDetails?.lastName}
                  </span>
                  <span className='text-x-small pt-1 text-N-500 font-regular'>
                    {userDetails?.email}
                  </span>
                </div>
                <i
                  className={CN(
                    'ri-arrow-right-circle-line text-[20px] leading-none group-hover:visible group-hover:text-P-400',
                    {
                      'visible !text-P-500': currentRoute
                        ?.split('/')[1]
                        ?.includes('profile'),
                      'invisible': !currentRoute
                        ?.split('/')[1]
                        ?.includes('profile'),
                    },
                  )}
                />
              </div>
            )}
          </div>

          <div
            className='bg-white flex justify-center px-2 py-[10px] items-center rounded-md h-fit hover:bg-R-25 cursor-pointer w-full border border-R-500 text-R-500 font-semibold'
            onClick={handleLogoutClick}>
            <i className='ri-logout-box-line' />
            {isExpanded && <div className='pl-2'>LOGOUT</div>}
          </div>
        </div>
      </motion.nav>
    </div>
  )
}

export default MainSideBar
