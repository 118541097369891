import React, { FC, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import CN from 'classnames'

import { CustomizeColumnSelectedContainer } from './CustomizeColumnSelectedContainer'

export interface CustomizeColumnsProps {
  [x: string]: any
}

export const CustomizeColumns: FC<CustomizeColumnsProps> = ({
  className,
  columns,
  onChange,
  section,
  localStorageKey,
  ...restProps
}: CustomizeColumnsProps) => {
  const CustomizeColumnsClasses = CN(
    `customize-columns flex justify-center w-full h-full`,
    className,
  )

  const columnses = {
    'colum-1': {
      name: 'Todo',
      items: columns,
    },
  }

  const { organizationId }: any = JSON.parse(
    localStorage.getItem('user') || '{}',
  )

  const items = JSON.parse(localStorage.getItem('items') as string) || []

  const [columnList, setColumnList] = React.useState(columnses)

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) {
      return
    }
    const { source, destination } = result
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }

  useEffect(() => {
    onChange && onChange(columnList['colum-1']?.items)
  }, [columnList])

  return (
    <div className={CustomizeColumnsClasses} {...restProps}>
      <DragDropContext
        onDragEnd={(result: any) =>
          onDragEnd(result, columnList, setColumnList)
        }>
        {Object.entries(columnList).map(([id, column]) => {
          return (
            <Droppable droppableId={id} key={id}>
              {(provided: any) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='p-[4px] w-full h-full'>
                    {column.items.map((item: any, idx: number) => {
                      if (item.Header)
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={idx}>
                            {(provided: any, snapshot: any) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                  }}
                                  className={CN(
                                    'user-select-none p-[8px] flex flex-row min-h-[32px] border-[1px] border-N-300 rounded mt-[8px] first:mt-0 bg-N-50',
                                    { '!bg-N-200': snapshot.isDragging },
                                  )}>
                                  <div className='flex items-center justify-between flex-shrink-0 w-full'>
                                    <div className='flex items-center flex-shrink-0 gap-x-[8px] text-base !font-medium'>
                                      <div>
                                        <i className='ri-more-2-fill'></i>
                                      </div>
                                      <span>{item.Header}</span>
                                    </div>
                                    <div>
                                      <CustomizeColumnSelectedContainer
                                        key={idx}
                                        defaultChecked={item.isVisible}
                                        enableVisible={item?.enableVisible}
                                        onClickColumnSelectedContainer={() => {
                                          if (item.isVisible) {
                                            items.splice(
                                              JSON.parse(
                                                localStorage.getItem(
                                                  localStorageKey,
                                                ) as any,
                                              )?.findIndex(
                                                (itm: any) =>
                                                  itm?.item === item?.Header,
                                              ),
                                              1,
                                            )
                                          } else {
                                            items.push({
                                              orgId: organizationId,
                                              item: item.Header,
                                              section: section,
                                            })
                                          }
                                          localStorage.setItem(
                                            localStorageKey,
                                            JSON.stringify(items),
                                          )
                                          const column: any = [
                                            ...columnList['colum-1'].items,
                                          ]
                                          column[idx].isVisible =
                                            !column[idx].isVisible
                                          setColumnList({
                                            'colum-1': {
                                              name: 'Todo',
                                              items: column,
                                            },
                                          })
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }}
                          </Draggable>
                        )
                    })}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
          )
        })}
      </DragDropContext>
    </div>
  )
}

CustomizeColumns.defaultProps = {}

export default CustomizeColumns
