/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, ReactNode } from 'react'
import CN from 'classnames'

interface BadgeProps {
  children?: ReactNode | string | number | undefined
  childrenClassName?: string | undefined
  className?: string | undefined
  isClose?: boolean
  isCustomStyles?: boolean
  isRounded?: boolean
  onClickClose?: any
  theme?: 'gray' | 'red' | 'yellow' | 'green'
  type?: 'default' | 'light'
}

export const Badge: FC<BadgeProps> = ({
  children,
  childrenClassName,
  className,
  isClose,
  isCustomStyles,
  isRounded,
  onClickClose,
  theme,
  type,
  ...restProps
}: BadgeProps) => {
  /** main badge styles */
  const BadgeClassName = !isCustomStyles
    ? CN(
        'flex items-center justify-center w-fit py-[2px] px-2 font-medium',
        className,
        {
          'bg-N-600 text-white': theme === 'gray' && type === 'default',
          'bg-R-500 text-white': theme === 'red' && type === 'default',
          'bg-P-500 text-white': theme === 'yellow' && type === 'default',
          'bg-G-500 text-white': theme === 'green' && type === 'default',

          'bg-N-25 text-N-500': theme === 'gray' && type === 'light',
          'bg-R-25 text-R-500': theme === 'red' && type === 'light',
          'bg-P-50 text-P-500': theme === 'yellow' && type === 'light',
          'bg-G-25 text-G-500': theme === 'green' && type === 'light',

          'rounded-[12px]': isRounded,
          'rounded-[4px]': !isRounded,
        },
      )
    : className

  return (
    <div className={BadgeClassName} {...restProps}>
      {/* badge inside text */}
      <div className={CN('badge-inside-text text-x-small', childrenClassName)}>
        {children}
      </div>

      {/* remove icon */}
      {isClose && (
        <i
          className='ri-close-fill pl-[5px] text-[12px] leading-none cursor-pointer'
          onClick={onClickClose}></i>
      )}
    </div>
  )
}

export default Badge

Badge.defaultProps = {
  children: 'Badge',
  childrenClassName: undefined,
  className: undefined,
  isCustomStyles: false,
  isClose: false,
  onClickClose: undefined,
  theme: 'gray',
  type: 'light',
  isRounded: false,
}
