interface SubMenuProps {
  id: number
  title: string
  icon: string
  link: string
  isActive: boolean
}

export type SubMenuType = SubMenuProps[]

interface MainMenuProps {
  id: number
  title: string
  icon: string
  link: string
  isActive: boolean
  isTempActive: boolean
  hasSubMenu?: boolean
  subMenu?: SubMenuType | []
}

export type MainMenuTypes = MainMenuProps[]

export const menuItems: MainMenuTypes = [
  {
    id: 1,
    title: 'Order',
    icon: 'ri-briefcase-line',
    link: '/',
    isActive: false,
    hasSubMenu: false,
    isTempActive: false,
    subMenu: [],
  },
  // {
  //   id: 2,
  //   title: 'Inventory',
  //   icon: 'ri-todo-line',
  //   link: '/inventory',
  //   isActive: false,
  //   isTempActive: false,
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       id: 1,
  //       title: 'Stock On Hand',
  //       icon: 'ri-hand-coin-line',
  //       link: '/inventory/stock-on-hand',
  //       isActive: false,
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   title: 'Inbound',
  //   icon: 'ri-government-line',
  //   link: '/inbound',
  //   isActive: false,
  //   isTempActive: false,
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       id: 1,
  //       title: 'Inbound Shipment',
  //       icon: 'ri-truck-line',
  //       link: '/inbound/inbound-shipment',
  //       isActive: false,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: 'Reports',
  //   icon: 'ri-file-list-3-line',
  //   link: '/reports',
  //   isActive: false,
  //   hasSubMenu: false,
  //   isTempActive: false,
  //   subMenu: [],
  // },
  // this below section for handle multiple level menus in phase 2 . side bar is already developed according to this type of menu
  // {
  //   id: 2,
  //   title: 'Dashboard',
  //   icon: 'ri-dashboard-line',
  //   link: '/',
  //   isActive: false,
  //   hasSubMenu: false,
  //   isTempActive: false,
  //   subMenu: [],
  // },
  // {
  //   id: 3,
  //   title: 'Test',
  //   icon: 'ri-file-text-line',
  //   link: '/test',
  //   isActive: false,
  //   isTempActive: false,
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       id: 1,
  //       title: 'Test-one',
  //       icon: 'ri-file-text-line',
  //       link: '/test/test1',
  //       isActive: false,
  //     },
  //     {
  //       id: 2,
  //       title: 'Test-two',
  //       icon: 'ri-file-text-line',
  //       link: '/test/test2',
  //       isActive: false,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: 'Testx',
  //   icon: 'ri-file-text-line',
  //   link: '/testx',
  //   isActive: false,
  //   isTempActive: false,
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       id: 1,
  //       title: 'Test-onex',
  //       icon: 'ri-file-text-line',
  //       link: '/testx/test1x',
  //       isActive: false,
  //     },
  //     {
  //       id: 2,
  //       title: 'Test-twox',
  //       icon: 'ri-file-text-line',
  //       link: '/testx/test2x',
  //       isActive: false,
  //     },
  //   ],
  // },
]

export default menuItems
