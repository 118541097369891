import React, { FC } from 'react'
import CN from 'classnames'
import { motion } from 'framer-motion'

export interface OverlayProps {
  className?: string | undefined
  children?: any
  isInline?: boolean
  variant?: 'dark' | 'light'
  [x: string]: any
}

export const Overlay: FC<OverlayProps> = ({
  className,
  children,
  isInline,
  variant,
  ...restProps
}: OverlayProps) => {
  const OverlayClasses = CN(
    `overlay  bottom-0 flex h-full justify-center left-0 p-[32px] right-0 w-full`,
    {
      'bottom-0 left-0 fixed right-0 top-0 z-[10000]': !isInline,
      'bg-N-800 bg-opacity-60': variant === 'dark',
      'bg-white bg-opacity-80': variant === 'light',
    },
    className,
  )

  return (
    <motion.div className={OverlayClasses} {...restProps}>
      {children}
    </motion.div>
  )
}

Overlay.defaultProps = {
  className: undefined,
  children: undefined,
  isInline: false,
  variant: 'dark',
}

export default Overlay
