import React from 'react'
import {
  toast as toastSrc,
  ToastContainer as ToastContainerSrc,
} from 'react-toastify'
import CN from 'classnames'

import { Alert } from './Alert'

import 'react-toastify/dist/ReactToastify.css'
import './Toast.scss'

const Msg = (props: any) => {
  const {
    alertHeader,
    alertBody,
    closeToast,
    onCloseIconClick,
    status,
    toastProps,
  } = props || {}

  return (
    <Alert
      alertHeader={alertHeader}
      alertBody={alertBody}
      onCloseIconClick={() => {
        closeToast()
        onCloseIconClick && onCloseIconClick(toastProps)
      }}
      status={status}
    />
  )
}

export const Toast = (props: any) => {
  const { alertHeader, alertBody, onCloseIconClick, status, style, restProps } =
    props || {}

  return toastSrc(
    <Msg
      alertHeader={alertHeader}
      alertBody={alertBody}
      onCloseIconClick={onCloseIconClick}
      status={status}
    />,
    { style: { padding: 0, ...style }, ...restProps },
  )
}

export const ToastContainer = (props: any) => {
  const { className, toastClassName } = props || {}

  return (
    <ToastContainerSrc
      position='top-right'
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      closeButton={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      style={{ padding: 0, width: '420px' }}
      toastStyle={{ padding: 0 }}
      bodyStyle={{ padding: 0, marginBottom: 0 }}
      className={CN('shadow-none', className)}
      toastClassName={CN('shadow-none mr-4', toastClassName)}
      theme='light'
    />
  )
}

Toast.defaultProps = {
  className: undefined,
  isCustomStyles: false,
}

export default Toast
