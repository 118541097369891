import React, { ButtonHTMLAttributes, FC, forwardRef, ReactNode } from 'react'
import CN from 'classnames'

import { Loader } from '../Loader'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: 'primary' | 'secondary' | 'link'
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  componentId?: string
  contentClassName?: string
  disabled?: boolean
  iconAfter?: string
  iconBefore?: string
  iconOnly?: boolean
  innerDivClass?: string
  isBlock?: boolean
  isCustomStyles?: boolean
  isLoading?: boolean
  onClick?: any
  size?: 'md' | 'lg'
}

export const Button = forwardRef<FC, ButtonProps>(
  (
    {
      appearance = 'primary',
      children,
      className,
      componentId,
      contentClassName,
      disabled,
      iconAfter,
      iconBefore,
      iconOnly,
      innerDivClass,
      isBlock,
      isCustomStyles,
      isLoading,
      onClick,
      size,
      ...restProps
    }: ButtonProps,
    ref: any,
  ) => {
    const commonClass = CN(
      'flex items-center align-center appearance-none box-border cursor-pointer rounded-[4px]',
      {
        'focus:shadow-md focus:shadow-B-300 active:ring-2 active:ring-offset-2 ring-P-300':
          !disabled,
      },
    )
    const variantClass = CN({
      'bg-P-500 hover:bg-P-300 uppercase':
        appearance === 'primary' && !disabled,
      'bg-white border border-[#A9B5B5] hover:bg-[#EBEEEE] uppercase':
        appearance === 'secondary' && !disabled,
      'hover:text-[#A9B5B5] focus:text-N-700':
        appearance === 'link' && !iconOnly,
    })

    const contentInnerClass = CN('btn__content content ', contentClassName, {
      'opacity-0 flex items-center justify-center pointer-events-none':
        isLoading,
      'underline underline-offset-2': appearance === 'link' && !iconOnly,
    })

    const iconOnlyClass = CN(
      'flex justify-center items-center',
      contentClassName,
    )

    const textClass = CN({
      'text-white': appearance === 'primary',
      'text-[#334242]': appearance === 'secondary' || appearance === 'link',
    })

    const sizeClass = CN({
      'px-3 py-2 h-[40px] text-base font-medium':
        size === 'md' && appearance !== 'link' && !iconOnly,

      'p-3 h-[48px] text-base font-medium':
        size === 'lg' && appearance !== 'link' && !iconOnly,

      'text-sm font-medium': appearance === 'link' && size === 'md',

      'text-base font-medium': appearance === 'link' && size === 'lg',

      'w-full text-center': isBlock,

      '!p-2 h-[40px] flex justify-center items-center': iconOnly,
    })

    const disabledClass = CN({
      '!cursor-not-allowed': disabled,
      '!bg-N-100 !text-N-300 uppercase': disabled && appearance !== 'link',
    })

    const ButtonClasses = !isCustomStyles
      ? CN(
          'btn',
          className,
          commonClass,
          variantClass,
          textClass,
          sizeClass,
          disabledClass,
        )
      : className

    return (
      <button
        data-component-id={`button-${componentId}`}
        type='button'
        className={ButtonClasses}
        disabled={disabled}
        ref={ref}
        onClick={(e) => {
          if (!disabled && !isLoading && onClick) {
            onClick(e)
          }
        }}
        {...restProps}>
        <div
          className={CN(
            'relative m-auto flex items-center justify-center align-center gap-1',
            innerDivClass,
          )}>
          {iconBefore && (
            <i
              className={CN(
                'btn__icon icon-before',
                { 'text-[16px]': size === 'md' },
                { 'text-[20px]': size === 'lg' },
                { hidden: isLoading },
                iconBefore,
              )}
            />
          )}
          {isLoading && (
            <div className='absolute'>
              <Loader
                appearance={appearance == 'primary' ? 'default' : 'secondary'}
                size={size}
              />
            </div>
          )}
          {!iconOnly && children && (
            <div className={contentInnerClass}>{children}</div>
          )}

          {iconOnly && children && (
            <div className={iconOnlyClass}>{children}</div>
          )}

          {iconAfter && (
            <i
              className={CN(
                'btn__icon icon-after',
                { 'text-[16px]': size === 'md' },
                { 'text-[20px]': size === 'lg' },
                { hidden: isLoading },
                iconAfter,
              )}
            />
          )}
        </div>
      </button>
    )
  },
)

Button.defaultProps = {
  appearance: 'primary',
  children: undefined,
  className: undefined,
  componentId: undefined,
  contentClassName: undefined,
  disabled: false,
  iconAfter: undefined,
  iconBefore: undefined,
  iconOnly: false,
  innerDivClass: undefined,
  isBlock: false,
  isCustomStyles: false,
  isLoading: false,
  onClick: undefined,
  size: 'md',
}

Button.displayName = 'Button'

export default Button
