import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface InboundShipmentReportExportAsCSVInterface {
  etaDateFrom?: string | null
  etaDateTo?: string | null
  orderDateFrom?: string | null
  orderDateTo?: string | null
  receiptedDateFrom?: string | null
  receiptedDateTo?: string | null
  search?: string
  status?: string
  stockOrderIds: any
  warehouse?: string
}

export const useInboundShipmentReportExportAsCSV = () => {
  const mutate = useMutation(
    ({
      etaDateFrom,
      etaDateTo,
      orderDateFrom,
      orderDateTo,
      receiptedDateFrom,
      receiptedDateTo,
      search,
      status,
      stockOrderIds,
      warehouse,
    }: InboundShipmentReportExportAsCSVInterface) => {
      return postAPI(`/StockOrder/CSVList`, {
        searchKeyword: search === '' ? null : search,
        warehouse: warehouse === 'All' ? null : warehouse,
        status: status === 'All' ? null : status,
        stockOrderIds: stockOrderIds?.length > 0 ? stockOrderIds : null,
        orderDateFrom: orderDateFrom || null,
        orderDateTo: orderDateTo || null,
        receiptedDateFrom: receiptedDateFrom || null,
        receiptedDateTo: receiptedDateTo || null,
        etaDateFrom: etaDateFrom || null,
        etaDateTo: etaDateTo || null,
      })
    },
  )

  return mutate
}

export default useInboundShipmentReportExportAsCSV
