import { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetInboundShipmentList,
  useGetWarehouseList,
  useInboundShipmentReportExportAsCSV,
  useInboundShipmentReportExportAsExcel,
} from 'framework/api/methods'
import {
  ARRIVED,
  ENTERED,
  STOCKPARTRECEIVED,
  STOCKRECEIVED,
} from 'static-data/InboundShipmentStatus'

import { Button, DateTimePicker, Dropdown } from 'components/atoms'
import { TableHeader, UITable } from 'components/common/UITable'
import { Toast } from 'components/molecules'
import { CustomizeColumModal } from 'components/molecules/CustomizeColumModal/CustomizeColumModal'
import {
  dateAndTimeIntoISTFormat,
  InboundShipmentStatusNameConversion,
  selectedDateEndDateAndTimeInISTFormat,
} from 'utils'

import { inboundOrderLineReportColumn } from './InboundOrderLineReportColumn'

export const InboundOrderLineReport: FC = () => {
  const orderStatusList = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: InboundShipmentStatusNameConversion(ARRIVED),
      value: ARRIVED,
    },
    {
      label: InboundShipmentStatusNameConversion(ENTERED),
      value: ENTERED,
    },
    {
      label: InboundShipmentStatusNameConversion(STOCKPARTRECEIVED),
      value: STOCKPARTRECEIVED,
    },
    {
      label: InboundShipmentStatusNameConversion(STOCKRECEIVED),
      value: STOCKRECEIVED,
    },
  ]

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const notify = (props: any) => Toast(props)
  const navigate = useNavigate()

  const [csvOrderData, setCsvOrderData] = useState<any>([])
  const [isOpenCustomizeModal, setIsOpenCustomizeModal] = useState(false)
  const [tableData, setTableData] = useState<any>()
  const [inboundShipmentTableColumn, setInboundShipmentTableColumn] =
    useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData?.skip || 0,
    take: tableData?.take || 10,
    search: '',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedRowsIds, setSelectedRowsIds] = useState<any>([])
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({
    label: 'All',
    value: 'All',
  })
  const [selectedStatus, setSelectedStatus] = useState<any>({
    label: 'All',
    value: 'All',
  })
  const [orderDate, setOrderDate] = useState<any>(null)
  const [receiptedDate, setReceiptedDate] = useState<any>(null)
  const [etaDate, setEtaDate] = useState<any>(null)

  const [selectedOriginalRowsIds, setSelectedOriginalRowsIds] = useState<any>(
    [],
  )

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [warehouseList, setWarehouseList] = useState([])
  const [isLoadingCSVDownload, setIsLoadingCSVDownload] = useState(false)
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<any>([])
  const [selectedAllIdList, setSelectedAllIdList] = useState<any>([])
  const [deselectIds, setDeselectIds] = useState<any>()

  useEffect(() => {
    getWarehouseList()
  }, [])

  useEffect(() => {
    getInboundShipments()
  }, [paginationVariables])

  const { mutate: getWarehouseListMutate } = useGetWarehouseList()

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedWarehouseList = successData.warehouses.map(
            (item: any) => {
              return {
                ...item,
                value: item?.code,
                label: item?.code,
              }
            },
          )
          setWarehouseList(updatedWarehouseList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }
  const temp = JSON.parse(
    localStorage.getItem('inboundShipmentTableColumn') as any,
  )

  /** Cart Table Column Handling */
  useEffect(() => {
    if (tableData?.stockOrders && tableData?.stockOrders?.length > 0) {
      const columns = inboundOrderLineReportColumn(navigate)
      if (temp.length === 0) {
        setInboundShipmentTableColumn(columns)
      } else {
        const tempCol = temp?.map((item: any) => {
          const tempItem = columns?.find((col: any) => col.id === item.id)
          return {
            ...item,
            Cell: tempItem?.Cell,
          }
        })
        setInboundShipmentTableColumn(tempCol)
      }
    }
  }, [tableData])

  /** API Call For get inbound shipment list  */
  const {
    mutate: getInboundShipmentListMutate,
    isLoading: getInboundShipmentListIsLoading,
  } = useGetInboundShipmentList()

  /** Process the get inbound shipment Table Data */
  async function getInboundShipments() {
    getInboundShipmentListMutate(
      {
        take: paginationVariables?.take,
        skip: paginationVariables?.skip,
        search: paginationVariables?.search,
        status: selectedStatus?.value,
        warehouse: selectedWarehouse.value,
        orderDateFrom: orderDate
          ? dateAndTimeIntoISTFormat(new Date(orderDate))
          : null,
        orderDateTo: selectedDateEndDateAndTimeInISTFormat(orderDate) || null,
        receiptedDateFrom: receiptedDate
          ? dateAndTimeIntoISTFormat(new Date(receiptedDate))
          : null,
        receiptedDateTo:
          selectedDateEndDateAndTimeInISTFormat(receiptedDate) || null,
        etaDateFrom: etaDate
          ? dateAndTimeIntoISTFormat(new Date(etaDate))
          : null,
        etaDateTo: selectedDateEndDateAndTimeInISTFormat(etaDate) || null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setTableData(successData)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetCustomizesColumns = () => {
    setInboundShipmentTableColumn(inboundOrderLineReportColumn(navigate))
  }

  useEffect(() => {
    const columns = inboundOrderLineReportColumn(navigate)

    const storedInboundShipmentTableColumn = JSON.parse(
      localStorage.getItem('inboundShipmentTableColumn') as any,
    )
    if (storedInboundShipmentTableColumn === null) {
      localStorage.setItem(
        'inboundShipmentTableColumn',
        JSON.stringify(inboundOrderLineReportColumn(navigate)),
      )
    } else {
      const tempCol = storedInboundShipmentTableColumn?.map((item: any) => {
        const tempItem = columns?.find((col: any) => col.id === item.id)
        return {
          ...item,
          Cell: tempItem?.Cell,
        }
      })
      setInboundShipmentTableColumn(tempCol)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'inboundShipmentTableColumn',
      JSON.stringify(inboundShipmentTableColumn),
    )
  }, [inboundShipmentTableColumn])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setPaginationVariables({
        ...paginationVariables,
        skip: 0,
        search: searchKeyWord,
      })
      setPageNumber(1)
    }
  }

  const resetFilter = () => {
    setSelectedRowsIds([])
    setSelectedOriginalRowsIds([])
    setSelectedRowIndexes([])
    setDeselectIds(null)
    setSelectedAllIdList([])

    setFilterActive(false)
    setSelectedStatus({ label: 'All', value: 'All' })
    setSelectedWarehouse({ label: 'All', value: 'All' })
    setOrderDate(null)
    setReceiptedDate(null)
    setEtaDate(null)
    setPaginationVariables({
      ...paginationVariables,
      skip: 0,
      search: '',
    })
    setPageNumber(1)
    setIsFilterMenuOpen(false)
  }

  useEffect(() => {
    setSelectedRowsIds([])
    setSelectedOriginalRowsIds([])
    setSelectedRowIndexes([])
    setDeselectIds(null)
  }, [pageNumber])

  useEffect(() => {
    const tempId = selectedRowsIds?.map((item: any) => item?.id)
    setSelectedOriginalRowsIds(tempId)
  }, [selectedRowsIds])

  /** API Call export csv using react-query */
  const { mutate: inboundShipmentReportExportAsCSVMutate } =
    useInboundShipmentReportExportAsCSV()
  /** Process the export csv*/
  async function inboundShipmentReportExportAsCSV() {
    setIsLoadingCSVDownload(true)
    inboundShipmentReportExportAsCSVMutate(
      {
        search: paginationVariables?.search,
        status: selectedStatus?.value,
        warehouse: selectedWarehouse.value,
        orderDateFrom: orderDate
          ? dateAndTimeIntoISTFormat(new Date(orderDate))
          : null,
        orderDateTo: selectedDateEndDateAndTimeInISTFormat(orderDate) || null,
        receiptedDateFrom: receiptedDate
          ? dateAndTimeIntoISTFormat(new Date(receiptedDate))
          : null,
        receiptedDateTo:
          selectedDateEndDateAndTimeInISTFormat(receiptedDate) || null,
        etaDateFrom: etaDate
          ? dateAndTimeIntoISTFormat(new Date(etaDate))
          : null,
        etaDateTo: selectedDateEndDateAndTimeInISTFormat(etaDate) || null,
        stockOrderIds: selectedAllIdList?.length > 0 ? selectedAllIdList : null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCsvOrderData(successData)

          setTimeout(() => {
            csvLinkEl?.current?.link.click()
            setIsLoadingCSVDownload(false)
            notify({
              alertHeader: 'Success',
              alertBody: 'CSV file downloaded successfully',
              status: 'Success',
            })
          }, 3000)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const onSuccessExport = () => {
    notify({
      alertHeader: 'Success',
      alertBody: 'Excel file downloaded successfully',
      status: 'Success',
    })
  }

  const onErrorExport = () => {
    notify({
      alertHeader: 'Error',
      alertBody: 'Something went wrong',
      status: 'Error',
    })
  }

  /** inbound shipment list report Export excel API */
  const {
    refetch: refetchExcel,
    isRefetching: isRefetchingExcelDownload,
    isLoading: isLoadingExcelDownload,
  } = useInboundShipmentReportExportAsExcel(
    {
      search:
        paginationVariables?.search === '' ? null : paginationVariables?.search,
      status: selectedStatus?.value === 'All' ? null : selectedStatus?.value,
      warehouse:
        selectedWarehouse.value === 'All' ? null : selectedWarehouse.value,
      orderDateFrom: orderDate
        ? dateAndTimeIntoISTFormat(new Date(orderDate))
        : null,
      orderDateTo: selectedDateEndDateAndTimeInISTFormat(orderDate) || null,
      receiptedDateFrom: receiptedDate
        ? dateAndTimeIntoISTFormat(new Date(receiptedDate))
        : null,
      receiptedDateTo:
        selectedDateEndDateAndTimeInISTFormat(receiptedDate) || null,
      etaDateFrom: etaDate ? dateAndTimeIntoISTFormat(new Date(etaDate)) : null,
      etaDateTo: selectedDateEndDateAndTimeInISTFormat(etaDate) || null,
      stockOrderIds: selectedAllIdList?.length > 0 ? selectedAllIdList : null,
    },
    onSuccessExport,
    onErrorExport,
  )

  useEffect(() => {
    if (deselectIds === 'All') {
      const matchingIds = tableData?.stockOrders.reduce(
        (ids: any, item: any) => {
          ids.push(item.id)
          return ids
        },
        [],
      )

      setSelectedOriginalRowsIds(
        selectedOriginalRowsIds?.filter(
          (item: any) => !matchingIds?.includes(item),
        ),
      )
    }
  }, [deselectIds, paginationVariables?.skip])

  useEffect(() => {
    if (selectedOriginalRowsIds?.length > 0) {
      const temp = [...selectedAllIdList, ...selectedOriginalRowsIds]
      const uniqueSet: any = new Set(temp)
      const uniqueArray = [...uniqueSet]

      if (deselectIds) {
        if (deselectIds === 'All') {
          const matchingIds = tableData?.stockOrders.reduce(
            (ids: any, item: any) => {
              ids.push(item.id)
              return ids
            },
            [],
          )
          setSelectedAllIdList(
            uniqueArray?.filter((item: any) => !matchingIds?.includes(item)),
          )
        } else {
          setSelectedAllIdList(
            uniqueArray?.filter((item: any) => item !== deselectIds),
          )
        }
      } else {
        setSelectedAllIdList(uniqueArray)
      }
    }
  }, [pageNumber, selectedOriginalRowsIds, deselectIds])

  useEffect(() => {
    if (tableData?.stockOrders) {
      const matchingIndexes = tableData?.stockOrders.reduce(
        (indexes: any, item: any, index: any) => {
          if (selectedAllIdList?.includes(item.id)) {
            indexes.push(index)
          }
          return indexes
        },
        [],
      )
      setSelectedRowIndexes(matchingIndexes)
    }
  }, [tableData?.stockOrders])

  return (
    <>
      <div className='flex flex-col h-fit w-full bg-white rounded-lg px-3 py-3 shadow-md'>
        <div className='text-heading-3 font-semibold text-N-800 pb-[23px]'>
          Inbound Order Line Report
        </div>

        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={
            'Search by Warehouse Order No, Customer Reference, SKU'
          }
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}
          handleSearchKeyPress={handleSearchKeyPress}>
          <div className='w-full flex justify-end gap-x-3 px-3 h-full'>
            <Button
              className='h-auto'
              appearance='secondary'
              iconBefore='ri-table-line text-[24px]'
              disabled={inboundShipmentTableColumn?.length === 0}
              onClick={() => {
                setIsOpenCustomizeModal(true)
              }}>
              Customize Columns
            </Button>

            <Button
              className='h-auto'
              appearance='secondary'
              iconBefore='ri-file-download-line text-[24px]'
              disabled={inboundShipmentTableColumn?.length === 0}
              onClick={() => {
                inboundShipmentReportExportAsCSV()
              }}>
              Export CSV
            </Button>

            <Button
              className='h-auto'
              iconBefore='ri-file-excel-2-line text-[24px]'
              disabled={inboundShipmentTableColumn?.length === 0}
              appearance='secondary'
              onClick={() => {
                refetchExcel()
              }}>
              Export Excel
            </Button>
          </div>
        </TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.5 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: 0, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex flex-col w-full pt-3 z-[10] gap-y-4'>
                <div className='flex justify-start gap-x-3'>
                  <Dropdown
                    label='By Warehouse'
                    placeholder='All'
                    value={selectedWarehouse}
                    className='w-1/3'
                    onChange={(selectedItem: any) => {
                      setSelectedWarehouse({
                        value: selectedItem?.value,
                        label: selectedItem?.label,
                      })
                    }}
                    options={[{ label: 'All', value: 'All' }, ...warehouseList]}
                  />

                  <Dropdown
                    label='By Status'
                    placeholder='All'
                    value={selectedStatus}
                    className='w-1/3'
                    onChange={(selectedItem: any) => {
                      setSelectedStatus({
                        value: selectedItem?.value,
                        label: selectedItem?.label,
                      })
                    }}
                    options={orderStatusList}
                  />

                  <div className='select-service-type-drop-down flex flex-col w-1/3 '>
                    <span className='text-xs font-medium text-N-700 pb-[3px]'>
                      By Order Date
                    </span>

                    <DateTimePicker
                      value={orderDate}
                      onChange={(e) => {
                        setOrderDate(e.target.value)
                      }}
                      placeholder='DD/MM/YYYY'
                      allowEdit={false}
                    />
                  </div>
                </div>

                <div className='flex justify-start gap-x-3 pr-6'>
                  <div className='select-service-type-drop-down flex flex-col w-1/3'>
                    <span className='text-xs font-medium text-N-700 pb-[3px]'>
                      By Receipted Date
                    </span>

                    <DateTimePicker
                      value={receiptedDate}
                      onChange={(e) => {
                        setReceiptedDate(e.target.value)
                      }}
                      placeholder='DD/MM/YYYY'
                      allowEdit={false}
                    />
                  </div>

                  <div className='select-service-type-drop-down flex flex-col w-1/3'>
                    <span className='text-xs font-medium text-N-700 pb-[3px]'>
                      By ETA
                    </span>

                    <DateTimePicker
                      value={etaDate}
                      onChange={(e) => {
                        setEtaDate(e.target.value)
                      }}
                      placeholder='DD/MM/YYYY'
                      allowEdit={false}
                    />
                  </div>
                </div>

                <div className='flex justify-start gap-x-2'>
                  <Button
                    disabled={
                      !orderDate &&
                      !receiptedDate &&
                      !etaDate &&
                      !selectedStatus?.value &&
                      !selectedWarehouse?.value
                    }
                    onClick={() => {
                      getInboundShipments()
                      setPageNumber(1)
                      setIsFilterMenuOpen(false)
                      setSelectedRowsIds([])
                      setSelectedOriginalRowsIds([])
                      setSelectedRowIndexes([])
                      setDeselectIds(null)
                      setSelectedAllIdList([])
                      if (
                        orderDate === null &&
                        receiptedDate === null &&
                        etaDate === null &&
                        selectedWarehouse?.value === 'All' &&
                        selectedStatus?.value === 'All'
                      ) {
                        setFilterActive(false)
                      } else {
                        setFilterActive(true)
                      }
                    }}>
                    Apply Filter
                  </Button>

                  <Button
                    appearance='secondary'
                    onClick={() => {
                      resetFilter()
                    }}>
                    Reset
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className='w-full h-[calc(80vh-171px)] relative pt-8'>
          <div
            className={CN(
              'styled-scroll overflow-auto w-full h-[calc(100%-58px)] bg-white',
            )}>
            <UITable
              data={tableData?.stockOrders || []}
              isLoading={
                getInboundShipmentListIsLoading ||
                isLoadingCSVDownload ||
                isRefetchingExcelDownload ||
                isLoadingExcelDownload
              }
              className=''
              columns={inboundShipmentTableColumn || []}
              allowRowSelection={true}
              isSorted={true}
              isManualSortBy={false}
              hasFooter={true}
              hasCheckBox={true}
              isHeader={true}
              isFilterEnabled={false}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
              totalRowCount={tableData?.totalCount || 0}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              forceSelectedRowIndexes={selectedRowIndexes}
              onRowSelect={(ids: any) => {
                setSelectedRowsIds(ids)
              }}
              onDeselectRowId={(e: any) => {
                setDeselectIds(e)
              }}
              onSelectAllRows={(select: any) => {
                setDeselectIds(!select ? 'All' : null)
              }}
            />
          </div>
        </div>
      </div>

      {/* for csv export */}
      <CSVLink
        filename='inbound-shipment-list.csv'
        data={csvOrderData}
        ref={csvLinkEl}
      />

      <CustomizeColumModal
        handleCustomizeCol={(col: any) => {
          setInboundShipmentTableColumn(col)
        }}
        columns={inboundShipmentTableColumn}
        handleCloseModal={() => {
          setIsOpenCustomizeModal(false)
        }}
        section='InboundShipment'
        isModalOpen={isOpenCustomizeModal}
        resetCustomizesColumns={resetCustomizesColumns}
        localStorageKey='inboundShipmentTableColumn'
      />
    </>
  )
}

export default InboundOrderLineReport
