import {useMutation} from 'react-query'
import {postAPI, putAPI} from 'framework/api/http'

interface UpdateUserDetailsInterface {
    id: string
    firstName: string
    lastName: string
    phoneNumber: string
    supportEmail: string
    streetAddress: string
    suburb: string
    state: any
    country: any
    postalCode: string
}

export const useUpdateUserDetails = () => {
    const mutate = useMutation(
        ({
             id,
             firstName,
             lastName,
             phoneNumber,
             supportEmail,
             streetAddress,
             suburb,
             state,
             country,
             postalCode,
         }: UpdateUserDetailsInterface) => {
            return postAPI(`/ClientProfile`, {
                id: id,
                phoneNumber: phoneNumber,
                firstName: firstName,
                lastName: lastName,
                supportEmail: supportEmail,
                address: {
                    lineOne: streetAddress || '',
                    lineTwo: '',
                    city: '',
                    suburb: suburb || '',
                    state: state?.label,
                    stateCode: state?.value,
                    country: country?.label,
                    countryCode: country?.value,
                    postCode: postalCode || '',
                },
            })
        },
    )

    return mutate
}

export default useUpdateUserDetails
