/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react'
import CN from 'classnames'

interface CustomizeColumnSelectedContainerProps {
  className?: string | undefined
  onClickColumnSelectedContainer?: () => void | undefined
  defaultChecked?: boolean
  enableVisible?: boolean
}

export const CustomizeColumnSelectedContainer: FC<
  CustomizeColumnSelectedContainerProps
> = ({
  defaultChecked,
  className,
  onClickColumnSelectedContainer,
  enableVisible,
  ...restProps
}: CustomizeColumnSelectedContainerProps) => {
  /** main customizeColumnSelectedContainer styles */
  const CustomizeColumnSelectedContainerClassName = CN(
    'pr-1 cursor-pointer',
    className,
  )

  const [isColumnSelected, setIsColumnSelected] = useState(defaultChecked)

  return (
    <div
      className={CustomizeColumnSelectedContainerClassName}
      aria-hidden={true}
      {...restProps}
      onClick={() => {
        setIsColumnSelected(!isColumnSelected)
        onClickColumnSelectedContainer && onClickColumnSelectedContainer()
      }}>
      {enableVisible && (
        <i
          className={CN('text-[24px] text-N-500', {
            'ri-eye-line': isColumnSelected,
            'ri-eye-off-line': !isColumnSelected,
          })}></i>
      )}
    </div>
  )
}

export default CustomizeColumnSelectedContainer

CustomizeColumnSelectedContainer.defaultProps = {
  className: undefined,
  onClickColumnSelectedContainer: undefined,
  defaultChecked: true,
  enableVisible: true,
}
