const moment = require('moment-timezone')

export const dateAndTimeToAEST = (incomingDate: any) => {
  const dateString = moment
    .utc(incomingDate)
    .tz('Australia/Sydney')
    .format('DD/MM/YYYY h:mm:ss a')

  return dateString
}

export default dateAndTimeToAEST
