import React, { FC, ReactNode } from 'react'
import CN from 'classnames'
import { motion } from 'framer-motion'

import { Button } from 'components/atoms'

interface RightSideModalProps {
  [x: string]: any
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  headerTitle?: string
  isActive?: boolean | undefined
  isCustomStyles?: boolean
  isFooterShow?: boolean
  isHeaderShow?: boolean
  isPrimaryButtonDisable?: boolean
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
  customWidth?: string | number | undefined
}

export const RightSideModal: FC<RightSideModalProps> = ({
  children,
  className,
  headerTitle,
  isActive,
  isCustomStyles,
  isFooterShow,
  isHeaderShow,
  isPrimaryButtonDisable,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  customWidth,
  ...restProps
}: RightSideModalProps) => {
  /** Overlay styles */
  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed top-0 bottom-0 left-0 right-0 z-50 flex justify-end bg-black bg-opacity-30',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** main modal container styles */
  const ModalContainerClassName = CN(
    'modal-container flex flex-col justify-start items-center bg-white shadow-lg rounded-sm h-full',
  )

  return (
    <div
      className={OverlayClassName}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        onOverlayClick && onOverlayClick()
      }}
      aria-hidden='true'
      {...restProps.overlayProps}>
      {/* Modal main container  */}

      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, x: '0', transition: { duration: 0.1 } },
          closed: { opacity: 1, x: '100%' },
        }}
        className={ModalContainerClassName}
        style={{ width: customWidth }}
        {...restProps.modalProps}
        onClick={(e: any) => e.stopPropagation()}>
        {isHeaderShow && (
          /* Modal Header  */
          <div
            className='modal-Header flex w-full justify-between p-4 bg-N-50 border-b-[1px] border-N-200'
            {...restProps.headerProps}>
            <span className='header-title flex justify-center items-center text-heading-3 font-semibold text-N-800'>
              {headerTitle}
            </span>

            <Button
              className='rounded-[4px]'
              {...restProps.headerCloseButtonProps}
              iconOnly
              appearance='secondary'
              onClick={() => {
                onHeaderCloseButtonClick && onHeaderCloseButtonClick()
              }}>
              <i className='ri-close-fill text-S-900 text-[24px] leading-none' />
            </Button>
          </div>
        )}

        {/* Modal body */}
        <div className='flex w-full h-full max-h-full overflow-y-auto styled-scroll py-3 px-4'>
          {children}
        </div>

        {/* Modal footer */}
        {isFooterShow && (
          <div
            className='modal-footer flex w-full gap-x-2 bg-N-50 items-center border-t-[1px] border-N-200 p-4'
            {...restProps.footerProps}>
            {/*footer secondary button*/}
            {secondaryButtonTitle !== '' && (
              <Button
                appearance='secondary'
                isBlock
                onClick={() => {
                  onClickSecondaryBtn && onClickSecondaryBtn()
                }}
                {...restProps.primaryButtonProps}>
                {secondaryButtonTitle}
              </Button>
            )}

            {/*footer primary button*/}
            {primaryButtonTitle !== '' && (
              <Button
                appearance='primary'
                isBlock
                onClick={() => {
                  onClickPrimaryBtn && onClickPrimaryBtn()
                }}
                disabled={isPrimaryButtonDisable}
                {...restProps.secondaryButtonProps}>
                {primaryButtonTitle}
              </Button>
            )}
          </div>
        )}
      </motion.div>
    </div>
  )
}

export default RightSideModal

RightSideModal.defaultProps = {
  children: undefined,
  className: undefined,
  headerTitle: undefined,
  isActive: true,
  isCustomStyles: false,
  isFooterShow: true,
  isHeaderShow: true,
  isPrimaryButtonDisable: false,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: undefined,
  secondaryButtonTitle: undefined,
  customWidth: 500,
}
