import { useQuery } from 'react-query'
import { post } from 'framework/api/http'

interface UseUniformListInterface {
  [x: string]: any
}

export const orderReportExportAsExcel = async ({
  queryKey,
}: UseUniformListInterface) => {
  const req = queryKey[1] || {}
  const res = await post(
    '/PartnerOrders/ExcelList',
    {
      ...req,
    },
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
      },
    },
  )

  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `order-list.xlsx`) //or any other extension
  document.body.appendChild(link)
  link.click()
}

export const useOrderReportExportAsExcel = (
  queryData?: any,
  onSuccess?: any,
  onError?: any,
) => {
  return useQuery(
    ['order-list-export-to-excel', queryData],
    orderReportExportAsExcel,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess,
      onError,
    },
  )
}

export default useOrderReportExportAsExcel
