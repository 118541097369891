import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useUpdateUserPassword } from 'framework/api/methods/profile'

import {
  Button,
  InputField,
  PageLoader,
  PasswordStrengthBar,
} from 'components/atoms'
import { Toast } from 'components/molecules'
import { passwordStrengthCalculate } from 'utils'

export const Security: FC = () => {
  const [formDetails, setFormDetails] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const { userDetails }: any = useSelector<any>((state) => state.user)

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [passwordStrength, setPasswordStrength] = useState(0)

  const notify = (props: any) => Toast(props)

  /** API Call for Update user password */
  const {
    mutate: updateUserPasswordMutate,
    isLoading: updateUserPasswordIsLoading,
  } = useUpdateUserPassword()

  /** Process update user password API call */
  async function updateUserPassword() {
    updateUserPasswordMutate(
      {
        username: userDetails.userName,
        currentPassword: formDetails.currentPassword,
        newPassword: formDetails.newPassword,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setFormDetails({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          })
          notify({
            alertHeader: 'Success',
            alertBody: successData.message
              ? successData.message
              : 'Password Updated Successfully',
            status: 'Success',
          })
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData.message
              ? errData.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  /** reset password button click */
  const onResetPasswordClick = () => {
    updateUserPassword()
  }

  return (
    <div className='flex flex-col h-full w-full '>
      {updateUserPasswordIsLoading && <PageLoader />}

      <div className='text-heading-3 font-semibold'>Change Password</div>

      <div className='text-N-500 text-small mt-1 w-full border-b border-b-N-200 pb-2'>
        Your password must be at least eight characters and should include a
        combination of numbers, letters and special characters.
      </div>

      <div className='w-1/2 flex flex-col mt-8'>
        <InputField
          type={showCurrentPassword ? 'text' : 'password'}
          label='Current Password'
          className='pb-6'
          placeholder='••••••••'
          value={formDetails.currentPassword}
          leadingIcon={
            formDetails.currentPassword !== '' ? (
              <i
                className={CN('hover:cursor-pointer', {
                  'ri-eye-off-line': showCurrentPassword == false,
                  'ri-eye-line': showCurrentPassword == true,
                })}
              />
            ) : undefined
          }
          leadingIconOnClick={() => {
            setShowCurrentPassword(!showCurrentPassword)
          }}
          onChange={(e: any) => {
            setFormDetails({ ...formDetails, currentPassword: e.target.value })
          }}
        />

        <InputField
          type={showNewPassword ? 'text' : 'password'}
          label='New Password'
          className='pb-2'
          placeholder='••••••••'
          value={formDetails.newPassword}
          leadingIcon={
            formDetails.newPassword !== '' ? (
              <i
                className={CN('hover:cursor-pointer', {
                  'ri-eye-off-line': showNewPassword == false,
                  'ri-eye-line': showNewPassword == true,
                })}
              />
            ) : undefined
          }
          leadingIconOnClick={() => {
            setShowNewPassword(!showNewPassword)
          }}
          onChange={(e: any) => {
            setFormDetails({ ...formDetails, newPassword: e.target.value })
            setPasswordStrength(passwordStrengthCalculate(e.target.value))
          }}
        />

        <PasswordStrengthBar
          isLabel
          progressCount={formDetails.newPassword === '' ? 0 : passwordStrength}
          isForPassword
        />

        <InputField
          type={showConfirmPassword ? 'text' : 'password'}
          label='Re-Try New Password'
          className='pb-8 mt-6'
          placeholder='••••••••'
          value={formDetails.confirmPassword}
          leadingIcon={
            formDetails.confirmPassword !== '' ? (
              <i
                className={CN('hover:cursor-pointer', {
                  'ri-eye-off-line': showConfirmPassword == false,
                  'ri-eye-line': showConfirmPassword == true,
                })}
              />
            ) : undefined
          }
          leadingIconOnClick={() => {
            setShowConfirmPassword(!showConfirmPassword)
          }}
          onChange={(e: any) => {
            setFormDetails({ ...formDetails, confirmPassword: e.target.value })
          }}
        />

        <Button
          size='md'
          className='w-1/4'
          appearance='primary'
          disabled={
            !formDetails?.currentPassword ||
            !formDetails?.newPassword ||
            !formDetails?.confirmPassword ||
            formDetails?.newPassword !== formDetails?.confirmPassword ||
            passwordStrength < 81
          }
          isLoading={updateUserPasswordIsLoading}
          onClick={onResetPasswordClick}>
          Save
        </Button>
      </div>
    </div>
  )
}

Security.defaultProps = {
  className: undefined,
}

export default Security
