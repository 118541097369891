/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import dragAndDropArea from 'assets/images/dragAndDropArea.svg'
import dragAndDropRectangleArea from 'assets/images/dragAndDropRectangleArea.svg'
import CN from 'classnames'

import { Button } from 'components/atoms'

import { Modal } from '../Modals'

import { getCroppedImg } from './ImageCropAndScale'

import './CustomizableEditProfilePictureModal.scss'

interface CustomizableEditProfilePictureModalProps {
  className?: string | undefined
  headerTitle?: string | undefined
  imageSource?: string
  isActive?: boolean | undefined
  isCustomStyles?: boolean
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  primaryButtonIsLoading?: boolean | undefined
  secondaryButtonTitle?: string
  isRectangleLogo?: boolean | undefined
  imageUrl: any
  setImageUrl: any
  setFiles: any
  [x: string]: any
}

export const CustomizableEditProfilePictureModal: FC<
  CustomizableEditProfilePictureModalProps
> = ({
  headerTitle,
  imageUrl,
  setImageUrl,
  isActive,
  setFiles,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  primaryButtonIsLoading,
  secondaryButtonTitle,
  isRectangleLogo,
}: CustomizableEditProfilePictureModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [newUploadPanel, setNewUploadPanel] = useState(true)
  const [fileName, setFileName] = useState('')
  const [isDisabledResetImageButton, setIsDisabledResetImageButton] =
    useState(true)
  const [warningMessage, setWarningMessage] = useState('')

  const [droppedFile, setDroppedFile] = useState<any>()

  useEffect(() => {
    setZoom(1)
    setNewUploadPanel(imageUrl ? false : true)
  }, [imageUrl])

  useEffect(() => {
    isActive && setCrop({ x: 0, y: 0 })
  }, [isActive])

  const onCropChange = (crop: any) => {
    setCrop(crop)
  }

  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    if (zoom !== 1.01) {
      const croppedImage = await getCroppedImg(
        isRectangleLogo,
        imageUrl,
        fileName,
        croppedAreaPixels,
      )
      setFiles(croppedImage)
    }
  }

  const onZoomChange = (e: any) => {
    setIsDisabledResetImageButton(false)
    setZoom(e.target.value)
  }

  const onZoomChangeCrop = (zoom: any) => {
    setIsDisabledResetImageButton(false)
    setZoom(zoom)
  }

  /** handle image upload file on drop */
  const onDrop = (accepted: any) => {
    if (
      accepted[0].type === 'image/jpeg' ||
      accepted[0].type === 'image/png' ||
      accepted[0].type === 'image/jpg'
    ) {
      setDroppedFile(accepted)
      addFile(accepted)
      setWarningMessage('')
    } else {
      const message = 'Please submit valid file type (jpg, png, jpeg)'
      setWarningMessage(message)
    }
  }

  const addFile = (file: any) => {
    setNewUploadPanel(false)
    file.map((file: any) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setFiles(file)
      setImageUrl(file.preview)
      setFileName(file.name)
    })
  }

  useEffect(() => {
    if (zoom === 1.01) {
      addFile(droppedFile)
    }
  }, [zoom])

  useEffect(() => {
    !isActive && setZoom(1)
    !isActive && setCrop({ x: 0, y: 0 })
  }, [isActive])

  return (
    <Modal
      isActive={isActive}
      className='w-[620px]'
      headerTitle={headerTitle}
      primaryButtonTitle={primaryButtonTitle}
      secondaryButtonTitle={secondaryButtonTitle}
      primaryButtonProps={{
        isLoading: primaryButtonIsLoading,
        disabled: !imageUrl,
      }}
      onHeaderCloseButtonClick={() => {
        setWarningMessage('')
        setImageUrl('')
        onHeaderCloseButtonClick && onHeaderCloseButtonClick()
      }}
      onClickPrimaryBtn={() => {
        onClickPrimaryBtn && onClickPrimaryBtn()
      }}
      onClickSecondaryBtn={() => {
        setWarningMessage('')
        setImageUrl('')
        onClickSecondaryBtn && onClickSecondaryBtn()
      }}>
      <div className='flex flex-col w-full justify-center items-center'>
        {/* Modal body */}
        {newUploadPanel ? (
          <div className='w-full'>
            <div className='flex flex-col w-full px-[118px] py-[50px]'>
              <Dropzone
                key={Math.random()}
                multiple={false}
                maxSize={4 * 1024 * 1024}
                maxFiles={1}
                onDrop={(accepted: any) => {
                  onDrop(accepted)
                }}
                onError={() => {
                  const message =
                    'Image size is too large. Please upload an image less than 4MB.'
                  setWarningMessage(message)
                }}>
                {({ getRootProps, getInputProps }: any) => (
                  <div id={Math.random().toString()}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        className={CN(
                          'flex flex-col justify-center items-center',
                        )}>
                        <img
                          src={
                            isRectangleLogo
                              ? dragAndDropRectangleArea
                              : dragAndDropArea
                          }
                          className={CN({
                            'w-[224px]': !isRectangleLogo,
                            'w-[360px] object-contain': isRectangleLogo,
                          })}
                          alt='drag-and-drop-area'
                        />

                        <span className='text-small text-N-400 py-3'>or</span>
                        <span className='flex text-small text-[#334242] font-medium cursor-pointer'>
                          <i className='ri-upload-2-fill mr-2' />
                          Upload a photo
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>

            {warningMessage && (
              <div className='flex w-full bg-R-500 justify-start items-center py-2'>
                <i className='ri-error-warning-line text-white mr-[11.6px] text-heading-4 ml-6' />
                <span className='text-small text-white font-Regular'>
                  {warningMessage}
                </span>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className='relative mt-[100px]'>
              <div className='relative'>
                <div
                  className={CN(
                    'crop-container relative top-0 left-0 right-0',
                    {
                      'h-[130px] w-[360px]': isRectangleLogo,
                      'h-56 w-56 ': !isRectangleLogo,
                    },
                  )}>
                  {!newUploadPanel && (
                    <Cropper
                      image={imageUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={!isRectangleLogo ? 1 : 4 / 1}
                      cropShape={!isRectangleLogo ? 'round' : 'rect'}
                      showGrid={false}
                      restrictPosition={false}
                      onCropChange={onCropChange}
                      onCropComplete={onCropComplete}
                      onZoomChange={onZoomChangeCrop}
                    />
                  )}
                </div>
              </div>
              <div className='absolute top-0 left-[-42px]'>
                <Button
                  iconOnly
                  className='mb-2'
                  onClick={() => {
                    setCrop({ x: 0, y: 0 })
                    setImageUrl('')
                    setNewUploadPanel(true)
                  }}
                  appearance='secondary'>
                  <i className='ri-delete-bin-line text-P-600' />
                </Button>
                <Button
                  disabled={isDisabledResetImageButton}
                  iconOnly
                  onClick={() => {
                    setCrop({ x: 0, y: 0 })
                    setZoom(1.01)
                    setIsDisabledResetImageButton(true)
                  }}
                  appearance='secondary'>
                  <i
                    className={CN('ri-refresh-line', {
                      'text-P-600': !isDisabledResetImageButton,
                      'text-N-400': isDisabledResetImageButton,
                    })}
                  />
                </Button>
              </div>
            </div>

            {/* image scaler */}

            <div className='scaler flex mt-3 mb-[68px] w-[224px]'>
              <i className='ri-indeterminate-circle-fill text-N-400' />
              <input
                className='w-full mx-3'
                name='scale'
                type='range'
                onChange={onZoomChange}
                min='0.1'
                max='2'
                value={zoom}
                step='0.01'
              />
              <i className='ri-add-circle-fill text-N-400' />
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default CustomizableEditProfilePictureModal

CustomizableEditProfilePictureModal.defaultProps = {
  className: undefined,
  headerTitle: undefined,
  isActive: true,
  isCustomStyles: false,
  onClickPrimaryBtn: undefined,
  onClickSecondaryBtn: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Save',
  primaryButtonIsLoading: false,
  secondaryButtonTitle: 'Cancel',
  isRectangleLogo: false,
}
