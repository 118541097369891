/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { InboundShipmentStatusBadge } from 'components/molecules'
import { dateToAEST } from 'utils'

export const InboundShipmentListColumn = (navigate: any) => {
  const COLUMNS = [
    {
      id: 'id',
      Header: '',
      accessor: 'id',
      forSorting: '',
      isSortable: false,
      hasFilter: false,
      isFixedSelect: false,
      className: 'min-w-[90px]',
      headerClassName: '!w-[90px]',
      commonClassName: '!w-[90px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start'>
          <i
            className='hover:cursor-pointer ri-eye-line'
            onClick={() => {
              navigate(
                `/inbound/inbound-shipment/inbound-shipment-details?order_id=${row.value}`,
              )
            }}
          />
        </div>
      ),
    },
    {
      id: 'wmsOrderNo',
      Header: 'WAREHOUSE ORDER NO',
      accessor: 'wmsOrderNo',
      forSorting: 'wmsOrderNo',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'customerReferance',
      Header: 'Customer Reference',
      accessor: 'customerReferance',
      forSorting: 'customerReferance',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: false,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'warehouseCode',
      Header: 'WAREHOUSE',
      accessor: 'warehouseCode',
      forSorting: 'warehouseCode',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'createdOn',
      Header: 'ORDER DATE',
      accessor: 'createdOn',
      forSorting: 'createdOn',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[130px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[130px]'>
          <span className='flex  break-words line-clamp-1'>
            {row.value ? dateToAEST(row.value) : ''}
          </span>
        </div>
      ),
    },
    {
      id: 'eta',
      Header: 'ETA',
      accessor: 'eta',
      forSorting: 'eta',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[130px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[130px]'>
          <span className='flex  break-words line-clamp-1'>
            {row.value ? dateToAEST(row.value) : ''}
          </span>
        </div>
      ),
    },
    {
      id: 'receiptDate',
      Header: 'RECEIPTED',
      accessor: 'receiptDate',
      forSorting: 'receiptDate',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[130px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[130px]'>
          <span className='flex  break-words line-clamp-1'>
            {row.value ? dateToAEST(row.value) : ''}
          </span>
        </div>
      ),
    },
    {
      id: 'status',
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[150px]'>
          {row.value && <InboundShipmentStatusBadge status={row.value} />}
        </div>
      ),
    },
  ]

  return COLUMNS
}

export default InboundShipmentListColumn
