import { useQuery } from 'react-query'
import { post } from 'framework/api/http'

export const inboundShipmentReportExportAsExcel = async ({ queryKey }: any) => {
  const req = queryKey[1] || {}
  const res = await post(
    '/StockOrder/ExcelList',
    {
      ...req,
    },
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
      },
    },
  )

  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `inbound-shipments-list.xlsx`)
  document.body.appendChild(link)
  link.click()
}

export const useInboundShipmentReportExportAsExcel = (
  queryData?: any,
  onSuccess?: any,
  onError?: any,
) => {
  return useQuery(
    ['inbound-shipment-list-export-to-excel', queryData],
    inboundShipmentReportExportAsExcel,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess,
      onError,
    },
  )
}

export default useInboundShipmentReportExportAsExcel
