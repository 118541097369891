import { FC } from 'react'
import pageLoading from 'assets/animations/page-loader.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

export interface PageLoaderProps {
  [x: string]: any
  className?: string | undefined
  customWidth?: string | number | undefined
}

export const PageLoader: FC<PageLoaderProps> = ({
  className,
  customWidth,
  ...restProps
}: PageLoaderProps) => {
  const PageLoaderClassNames = CN(
    'flex flex-col w-full h-full items-center justify-center absolute z-[99] left-0 top-0 bg-white bg-opacity-70 cursor-not-allowed',
    className,
  )

  const AnimatedView = () => {
    const options = {
      animationData: pageLoading,
      loop: true,
      autoplay: true,
      speed: 5,
    }

    const { View } = useLottie(options)

    return View
  }
  return (
    <div className={PageLoaderClassNames} {...restProps}>
      <div style={{ width: customWidth }}>
        <AnimatedView />
      </div>

      <span className='flex justify-center font-medium text-small text-N-700 pt-[10px]'>
        Please wait while we work on this
      </span>
    </div>
  )
}

PageLoader.defaultProps = {
  className: undefined,
  customWidth: 82,
}

export default PageLoader
