import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface OrderReportExportInterface {
  searchKeyword?: string
  status?: string
  integration?: string
  partnerOrderId: any
  from: any
  to: any
}

export const useOrderReportExportAsCSV = () => {
  const mutate = useMutation(
    ({
      searchKeyword,
      status,
      integration,
      partnerOrderId,
      from,
      to,
    }: OrderReportExportInterface) => {
      return postAPI(`/PartnerOrders/CsvList`, {
        searchKeyword: searchKeyword === '' ? null : searchKeyword,
        status: status === 'All' ? null : status,
        integration: integration === 'All' ? null : integration,
        partnerOrderId: partnerOrderId?.length > 0 ? partnerOrderId : null,
        from: from || null,
        to: to || null,
      })
    },
  )

  return mutate
}

export default useOrderReportExportAsCSV
