import { FC } from 'react'
import authBackground from 'assets/images/auth-background.svg'
import loginImg from 'assets/images/login-img.svg'

import { Footer } from 'components/common/Footer'
import { ToastContainer } from 'components/molecules'

export interface AuthLayoutFullProps {
  className?: string | undefined
  [x: string]: any
}

export const AuthLayout: FC<AuthLayoutFullProps> = ({
  children,
}: AuthLayoutFullProps) => {
  return (
    <div className='flex absolute h-full w-full back-drop'>
      <div className='flex flex-col h-full items-center justify-center w-7/12'>
        <img src={loginImg} alt='' className='' />
        <div className='w-[448px]'>{children}</div>
        <Footer />
      </div>
      <ToastContainer toastClassName='mt-[62px]' />
    </div>
  )
}

AuthLayout.defaultProps = {
  className: undefined,
}

export default AuthLayout
