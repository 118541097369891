/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
import CN from 'classnames'
import { motion } from 'framer-motion'
import {
  setIsExpanded,
  setMenu as acSetMenu,
} from 'store/reducers/side-menu/mainSideMenuReducer'

interface MenuItemProps {
  menuItems: any
  item: any | []
  index: number
  currentRoute: string
  isExpanded: boolean
}

/** this component for handle main side bar items actions and sub menu items action */
const MenuItem: FC<MenuItemProps> = ({
  item,
  index,
  menuItems,
  isExpanded,
  currentRoute,
}) => {
  const { id, title, icon, subMenu, hasSubMenu, isActive, link, isTempActive } =
    item

  const navigate = useNavigate()
  const dispatch: any = useDispatch()

  /** handle main menu items click */
  const handleMainMenuItemClick = (index: number) => {
    const toBeUpdatedMenuItems = menuItems?.map((menuItem: any) => {
      if (menuItem.id !== index) {
        return {
          ...menuItem,
          isActive: menuItem?.hasSubMenu
            ? menuItem?.subMenu?.some((itm: any) => itm?.isActive)
            : menuItem?.isActive,
          isTempActive: false,
        }
      } else {
        !hasSubMenu && navigate(menuItem.link)

        return {
          ...menuItem,
          isActive: menuItem?.hasSubMenu
            ? menuItem?.subMenu?.some((itm: any) => itm?.isActive)
            : menuItem?.isActive,
          isTempActive: !menuItem?.isTempActive,
        }
      }
    })
    dispatch(acSetMenu(toBeUpdatedMenuItems))
  }

  const motionVariantsTitle = {
    initialParam: {
      y: -20,
    },
    animateParam: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
    exitParam: {
      opacity: 0,
    },
  }

  /** handle main menu object when click sub list item's inside items  */
  const handleSubMenuItemClick = (selectedId: any) => {
    const tempSideMenu = menuItems?.map((menuItem: any) => {
      if (menuItem.isTempActive || menuItem.isActive) {
        const temp = menuItem.subMenu?.map((subMenuItem: any) => {
          if (subMenuItem.id == selectedId) {
            if (!subMenuItem.isActive) {
              if (!subMenuItem.hasSubMenu) {
                navigate(subMenuItem.link)
              }
              return { ...subMenuItem, isActive: true }
            } else {
              return { ...subMenuItem, isActive: false }
            }
          } else {
            return { ...subMenuItem, isActive: false }
          }
        })
        return { ...menuItem, subMenu: temp }
      } else {
        return { ...menuItem, isActive: false }
      }
    })
    dispatch(acSetMenu(tempSideMenu))
  }

  return (
    <div
      key={index}
      // to={!hasSubMenu && link}
      onClick={(e) => {
        e.stopPropagation()
        handleMainMenuItemClick(id)
        hasSubMenu && dispatch(setIsExpanded(true))
      }}
      className={CN('cursor-pointer w-full rounded-lg', {
        'bg-P-50': isTempActive,
      })}>
      {/* // */}
      <div
        className={CN('flex w-full items-center rounded-lg group', {
          '!bg-P-100 text-P-600': isActive,
          'bg-white text-N-700 hover:bg-P-50': !isActive,
          'bg-P-50': isTempActive,
        })}>
        <div
          className={CN(
            'h-11 w-11 flex justify-center items-center text-[16px]',
          )}
          data-tip={title}
          data-for={title}>
          <div className={CN('w-full flex justify-center relative pl-2')}>
            <div
              className={CN(
                'flex h-full  bg-P-600 absolute left-0 rounded-lg group-hover:w-[4px] group-hover:bg-P-400',
                {
                  'visible w-[4px]': isActive,
                },
              )}></div>

            <i className={CN('-ml-2', icon)} />
          </div>
        </div>

        {isExpanded && (
          <div className='flex items-center justify-between w-full pr-2 text-small'>
            {title}
            {item.isTempActive && item.hasSubMenu && (
              <i className='ri-arrow-up-s-line' />
            )}
            {!item.isTempActive && item.hasSubMenu && (
              <i className='ri-arrow-down-s-line' />
            )}
          </div>
        )}
      </div>

      {/* {isActive && hasSubMenu && <div>submenu list</div>} */}

      {isTempActive && hasSubMenu && isExpanded && (
        <div className='mt-2'>
          {subMenu?.map((item: any, index: number) => (
            <div
              key={index}
              className='flex cursor-pointer'
              onClick={(e) => {
                e.stopPropagation()
                currentRoute !== item?.link && handleSubMenuItemClick(item.id)
              }}>
              <div className='w-full flex flex-col items-center justify-between cursor-pointer mb-1 px-1'>
                <div className='w-full flex flex-col '>
                  <motion.div
                    className={CN('flex flex-col w-full')}
                    initial='initialParam'
                    animate='animateParam'
                    exit='exitParam'
                    variants={
                      isActive &&
                      link?.split('/')[1] !== currentRoute?.split('/')[1]
                        ? motionVariantsTitle
                        : undefined
                    }>
                    <div
                      className={CN(
                        'group relative w-full flex justify-between rounded-md py-2 px-2 text-small hover:bg-P-100 hover:text-N-500 hover:font-SemiBold',
                        {
                          'text-P-500 font-SemiBold bg-P-100': item?.isActive,
                          'text-N-500': !item?.isActive,
                        },
                      )}>
                      <div
                        className={CN(
                          'flex h-[60%] top-[20%] bg-P-600 absolute left-0 rounded-lg group-hover:w-[4px] group-hover:bg-P-400',
                          {
                            'visible w-[4px]': item?.isActive,
                          },
                        )}></div>
                      <span>
                        <i className={CN('ml-5', item.icon)} /> {item.title}
                      </span>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MenuItem
