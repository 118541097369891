/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userDetails: {
    address: {
      lineOne: '',
      lineTwo: '',
      city: '',
      suburb: '',
      state: '',
      stateCode: '',
      country: '',
      countryCode: '',
      postCode: '',
    },
    avatarUrl: null,
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    identityGuid: '',
    isActive: false,
    partnerCode: '',
    passwordResetRequestedOn: null,
    phoneNumber: null,
    roleId: '',
    roleName: '',
    supportEmail: '',
    updatedOn: '',
    userName: '',
    userPermissions: [],
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      state.userDetails = {}
    },

    setUserDetailsState(state, { payload }) {
      state.userDetails = payload
    },

    setUserPermissionsState(state, { payload }) {
      state.userDetails.userPermissions = payload
    },
  },
})

export const {
  resetToInitialState,
  setUserDetailsState,
  setUserPermissionsState,
} = userSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setUserDetails =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserDetailsState(value))
  }

export const setUserPermissions =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserPermissionsState(value))
  }

export default userSlice.reducer
