/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode } from 'react'
import CN from 'classnames'

interface InputFieldProps {
  className?: string | undefined
  description?: string | undefined
  hintText?: string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isErrored?: boolean
  isRequired?: boolean
  label?: string | undefined
  leadingIcon?: ReactNode | undefined
  leadingIconOnClick?: (e: any) => void | undefined
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string | undefined
  textFiledClassName?: string | undefined
  trailingIcon?: ReactNode | undefined
  trailingIconOnClick?: (e: any) => void | undefined
  type?: 'text' | 'email' | 'number' | 'password' | 'search' | undefined
  value?: string
  [x: string]: any
}

export const InputField: FC<InputFieldProps> = ({
  className,
  description,
  hintText,
  isCustomStyles,
  isDisabled,
  isErrored,
  isRequired,
  label,
  leadingIcon,
  leadingIconOnClick,
  onChange,
  onKeyPress,
  placeholder,
  textFiledClassName,
  trailingIcon,
  trailingIconOnClick,
  type,
  value,
  ...restProps
}: InputFieldProps) => {
  /** text field main class name */
  const textFieldMainClassName = !isCustomStyles
    ? CN('main-container flex flex-col', className)
    : className

  /** helper text style */
  const hintTextClassName = CN('text-small font-regular pt-1', {
    'text-N-500': !isErrored,
    'text-R-500': isErrored,
  })

  /** text input style */
  const TextInputFieldClassName = CN(
    'flex w-full items-center py-2.5 text-N-700 rounded-[2px] outline shadow-sm focus:outline-[2px] focus:outline-P-200 hover:bg-N-50 text-small disabled:bg-N-100',
    textFiledClassName,
    {
      'outline-N-300': !isErrored,
      '!outline-2 outline-R-500': isErrored,
    },
    {
      'px-3': trailingIcon === undefined && leadingIcon === undefined,
      'pr-3 pl-[40px]': trailingIcon,
      'pr-10 pl-3': leadingIcon,
    },
  )

  return (
    <div className={textFieldMainClassName} {...restProps}>
      {/* label */}
      {label !== undefined && (
        <div className='label-container text-small pb-1'>
          <div className='label text-base flex text-N-800 font-medium'>
            {/* label text */}
            <span>{label}</span>

            {/* required */}
            {isRequired && <span className='text-R-500 pl-[2px]'>*</span>}
          </div>

          {/* description */}
          {description && (
            <span className='text-N-500 font-regular pt-1'>
              ({description})
            </span>
          )}
        </div>
      )}

      {/* form fields*/}
      <div className='relative flex items-center'>
        {/*custom trailing icon*/}
        {trailingIcon && (
          <div
            className={CN('absolute text-N-400 text-[20px] pl-3', {
              '!text-N-300': isDisabled,
            })}
            onClick={trailingIconOnClick}>
            {trailingIcon}
          </div>
        )}

        {/* input field */}
        <input
          type={type}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          disabled={isDisabled}
          className={TextInputFieldClassName}
          {...restProps.inputProps}
        />

        {/*custom leading icon*/}
        {leadingIcon && (
          <div
            className={CN('absolute text-N-400 text-[20px] right-4', {
              '!text-N-300': isDisabled,
            })}
            onClick={leadingIconOnClick}>
            {leadingIcon}
          </div>
        )}
      </div>

      {/* helper text */}
      {hintText !== undefined && (
        <span className={hintTextClassName}>{hintText}</span>
      )}
    </div>
  )
}

export default InputField

InputField.defaultProps = {
  className: undefined,
  description: undefined,
  hintText: undefined,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isRequired: false,
  label: undefined,
  leadingIcon: undefined,
  leadingIconOnClick: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: 'Placeholder',
  textFiledClassName: undefined,
  trailingIcon: undefined,
  trailingIconOnClick: undefined,
  type: 'text',
  value: undefined,
}
