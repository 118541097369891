import {
  ALLIED,
  AUSPOST,
  AUSPOSTINTERNATIONAL,
  DIRECT,
  DSE,
  ICSWMS,
  MACHSHIP,
  SMARTSEND,
  STARSHIPIT,
} from 'static-data/courierList'

export const courierNameConversion = (courierName: string | undefined) => {
  let name = ''

  switch (courierName) {
    case AUSPOST:
      name = 'Australia Post'
      break
    case AUSPOSTINTERNATIONAL:
      name = 'Australia Post International'
      break
    case ALLIED:
      name = 'Allied Express'
      break
    case DIRECT:
      name = 'Direct Express'
      break
    case DSE:
      name = 'DSE'
      break
    case MACHSHIP:
      name = 'Machship'
      break
    case SMARTSEND:
      name = 'Smart Send'
      break
    case STARSHIPIT:
      name = 'Starshipit'
      break
    default:
      name = courierName || ''
      break
  }

  return name
}

export default courierNameConversion
