import {
  ARRIVED,
  ENTERED,
  STOCKPARTRECEIVED,
  STOCKRECEIVED,
} from 'static-data/InboundShipmentStatus'

export const InboundShipmentStatusNameConversion = (
  status: string | undefined,
) => {
  let inboundShipmentStatus = ''

  switch (status) {
    case ARRIVED:
      inboundShipmentStatus = 'Arrived'
      break
    case ENTERED:
      inboundShipmentStatus = 'Created'
      break
    case STOCKPARTRECEIVED:
      inboundShipmentStatus = 'Receipt in Progress'
      break
    case STOCKRECEIVED:
      inboundShipmentStatus = 'Receipt Completed'
  }

  return inboundShipmentStatus
}

export default InboundShipmentStatusNameConversion
