import { dateAndTimeIntoISTFormat } from './dateAndTimeIntoISTFormat'

export const selectedDateEndDateAndTimeInISTFormat = (
  incomingDateTime: any,
) => {
  if (incomingDateTime === null) return null

  const istDate = new Date(incomingDateTime)

  const formattedDateAndTime = new Date(istDate.setDate(istDate?.getDate() + 1))

  return incomingDateTime
    ? dateAndTimeIntoISTFormat(formattedDateAndTime)
    : null
}

export default selectedDateEndDateAndTimeInISTFormat
