/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'

import './DateTimeRangePicker.scss'

export interface DateTimeRangePickerProps {
  endPlaceHolder?: string
  endValue?: any
  isErrored?: boolean
  onEndChange?: (e: any) => void | undefined
  onStartChange?: (e: any) => void | undefined
  startPlaceHolder?: string
  startValue?: any
  [x: string]: any
}

export const DateTimeRangePicker: FC<DateTimeRangePickerProps> = ({
  endPlaceHolder,
  endValue,
  isErrored,
  onEndChange,
  onStartChange,
  startPlaceHolder,
  startValue,
  ...restProps
}: DateTimeRangePickerProps) => {
  return (
    <div
      className={CN('consign-date-time-picker', {
        '!outline-R-500 !outline-2': isErrored,
      })}>
      <DateTimePickerComponent
        id='datetimepicker'
        onChange={onStartChange}
        value={startValue}
        strictMode={false}
        placeholder={startPlaceHolder}
        allowEdit={false}
        enabled
        step={30}
      />

      <DateTimePickerComponent
        id='datetimepicker'
        onChange={onEndChange}
        value={endValue}
        strictMode={false}
        placeholder={endPlaceHolder}
        allowEdit={false}
        enabled
        step={30}
      />
    </div>
  )
}

DateTimeRangePicker.defaultProps = {
  endPlaceHolder: new Date().toLocaleDateString() + ' 12:00 AM',
  endValue: undefined,
  onEndChange: undefined,
  onStartChange: undefined,
  startPlaceHolder: new Date().toLocaleDateString() + ' 12:00 AM',
  startValue: undefined,
  isErrored: false,
}

export default DateTimeRangePicker
