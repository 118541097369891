export const BACKORDER = 'BACKORDER'
export const CANCELLED = 'CANCELLED'
export const CLOSED = 'CLOSED'
export const CONFIRMED = 'CONFIRMED'
export const EXCEPTION = 'EXCEPTION'
export const FAILED = 'FAILED'
export const ONHOLD = 'ONHOLD'
export const OPEN = 'OPEN'
export const PARTIALCONSIGNED = 'PARTIAL-CONSIGNED'
export const RECEIVED = 'RECEIVED'
export const TOBECONSIGNED = 'TOBECONSIGNED'
