import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetWarehouseListInterface {
  name?: string
  isActive?: any
}

export const useGetWarehouseList = () => {
  const mutate = useMutation(
    ({ name, isActive }: GetWarehouseListInterface) => {
      return get(`/Warehouse?isActive=${isActive}`)
    },
  )

  return mutate
}

export default useGetWarehouseList
