import React, { FC, forwardRef } from 'react'
import CN from 'classnames'

interface RadioProps {
  className?: string | undefined
  defaultChecked?: boolean
  helperText?: string | undefined
  id?: string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  labelText?: string | undefined
  name?: string | undefined
  onChange?: any | undefined
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      className,
      defaultChecked,
      helperText,
      id,
      isCustomStyles,
      isDisabled,
      isInvalid,
      labelText,
      name,
      onChange,
      ...restProps
    }: RadioProps,
    ref,
  ) => {
    const MainClassName = !isCustomStyles
      ? CN('ParentClass flex', className)
      : className

    const ButtonClassName = CN(
      'form-check-input appearance-none rounded-full h-4 w-4 border border-N-200 bg-white focus:outline-none focus:ring-offset-1 focus:ring-[2px] transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
      {
        'checked:bg-white checked:border-P-400 checked:border-[5px] checked:hover:bg-white hover:bg-N-200 active:bg-N-400 focus:ring-P-300':
          !isDisabled && !isInvalid,
        'disabled:bg-N-200 disabled:checked:bg-N-50 disabled:checked:border-N-200 disabled:checked:border-[5px]':
          isDisabled,
        'border-R-400 focus:ring-R-200': isInvalid && !isDisabled,
      },
    )

    return (
      <label className={MainClassName} htmlFor={id}>
        <div className='left-side'>
          <input
            id={id}
            onChange={onChange}
            ref={ref}
            type='radio'
            className={ButtonClassName}
            defaultChecked={defaultChecked}
            disabled={isDisabled}
            name={name}
            {...restProps}
          />
        </div>

        <div className='right-side flex flex-col justify-center pl-2'>
          {labelText && (
            <span className='font-regular text-N-700'>{labelText}</span>
          )}
          {helperText && (
            <span className='font-regular text-x-small text-N-400'>
              {helperText}
            </span>
          )}
        </div>
      </label>
    )
  },
)

Radio.defaultProps = {
  className: undefined,
  defaultChecked: undefined,
  helperText: undefined,
  id: undefined,
  isCustomStyles: undefined,
  isDisabled: false,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
}

export default Radio
