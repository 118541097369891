import React, { FC, ReactNode } from 'react'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { Button, Overlay } from 'components/atoms'

interface ModalProps {
  [x: string]: any
  animationType?:
    | 'fade'
    | 'slideFromTop'
    | 'slideFromBottom'
    | 'slideFromLeft'
    | 'slideFromRight'
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  customFooterButtonTitle?: string
  headerTitle?: string
  isActive?: boolean | undefined
  isConfirmationModal?: boolean
  isCustomFooter?: boolean
  isFooterShow?: boolean
  isHeaderShow?: boolean
  isInline?: boolean
  isSeparator?: boolean
  onClickCustomFooterBtn?: () => void | undefined
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
}

export const Modal: FC<ModalProps> = ({
  animationType,
  children,
  className,
  customFooterButtonTitle,
  headerTitle,
  isActive,
  isConfirmationModal,
  isCustomFooter,
  isFooterShow,
  isHeaderShow,
  isInline,
  isSeparator,
  onClickCustomFooterBtn,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  ...restProps
}: ModalProps) => {
  const ModalClasses = CN(
    'modal bg-white rounded-[4px] text-N-800 flex flex-col max-w-[90%] max-h-[94%] overflow-hidden cursor-auto ',
    className,
    {
      'fixed transform-y-[-50%] z-[1100]': !isInline,
    },
  )

  /** modal header styles */
  const ModalHeaderClassName = CN(
    'modal-Header flex w-full justify-between p-4 bg-N-50',
    {
      'border-b-[1px] border-N-200': isSeparator,
    },
  )

  const overLayAnimationVariant = {
    initial: { opacity: 0.5 },
    isOpen: { opacity: 1, transition: { duration: 0.1 } },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  }

  let modalAnimationVariant = {}

  switch (animationType) {
    case 'fade':
      modalAnimationVariant = {
        initial: { opacity: 0.5 },
        isOpen: {
          opacity: 1,
          transition: { duration: 0.1 },
        },
        exit: { opacity: 0, transition: { duration: 0.1 } },
      }
      break
    case 'slideFromTop':
      modalAnimationVariant = {
        initial: { y: '-100%' },
        isOpen: { y: '0%', transition: { duration: 0.1 } },
        exit: { y: '-100%', transition: { duration: 0.1 } },
      }
      break
    case 'slideFromBottom':
      modalAnimationVariant = {
        initial: { y: '100%' },
        isOpen: { y: '0%', transition: { duration: 0.1 } },
        exit: { y: '100%', transition: { duration: 0.1 } },
      }
      break
    case 'slideFromLeft':
      modalAnimationVariant = {
        initial: { x: '-100%' },
        isOpen: { x: '0%', transition: { duration: 0.1 } },
        exit: { x: '-100%', transition: { duration: 0.1 } },
      }
      break
    case 'slideFromRight':
      modalAnimationVariant = {
        initial: { x: '100%' },
        isOpen: { x: '0%', transition: { duration: 0.1 } },
        exit: { x: '100%', transition: { duration: 0.1 } },
      }
      break
    default:
      modalAnimationVariant = {
        initial: { opacity: 0.5 },
        isOpen: {
          opacity: 1,
          transition: { duration: 0.1 },
        },
        exit: { opacity: 0, transition: { duration: 0.1 } },
      }
      break
  }

  return (
    <AnimatePresence>
      {isActive && (
        <Overlay
          animate={!isInline && 'isOpen'}
          exit={!isInline && 'exit'}
          className={CN(
            { 'cursor-pointer': onOverlayClick },
            'flex justify-center items-center',
          )}
          initial={!isInline && 'initial'}
          isInline={isInline}
          onClick={onOverlayClick}
          variants={!isInline && overLayAnimationVariant}>
          <motion.div
            className={ModalClasses}
            variants={modalAnimationVariant}
            onClick={(e) => e.stopPropagation()}
            {...restProps}>
            {isHeaderShow && (
              <div className={ModalHeaderClassName} {...restProps.headerProps}>
                <span className='header-title flex justify-center items-center text-heading-3 font-semibold text-N-800'>
                  {headerTitle}
                </span>

                <Button
                  className='rounded-[4px] w-10'
                  {...restProps.headerCloseButtonProps}
                  iconOnly
                  appearance='secondary'
                  onClick={() => {
                    onHeaderCloseButtonClick && onHeaderCloseButtonClick()
                  }}>
                  <i className='ri-close-fill text-S-900 text-[24px] leading-none' />
                </Button>
              </div>
            )}

            <div className='flex h-auto max-h-[70vh] overflow-y-auto styled-scroll w-full'>
              {/* Modal body */}
              {children}
            </div>

            {/* Modal footer */}
            {isFooterShow && (
              <div
                className={CN(
                  'modal-footer flex justify-end w-full gap-x-2 bg-N-50 items-center border-t-[1px]',
                  {
                    'border-N-200 bg-N-50 p-4': !isConfirmationModal,
                    'border-S-300 bg-S-50 py-3 px-6': isConfirmationModal,
                  },
                )}
                {...restProps.footerProps}>
                {isCustomFooter && (
                  <Button
                    appearance='secondary'
                    onClick={() => {
                      onClickCustomFooterBtn && onClickCustomFooterBtn()
                    }}
                    {...restProps.customFooterButtonProps}>
                    {customFooterButtonTitle}
                  </Button>
                )}

                {/*footer secondary button*/}
                {secondaryButtonTitle !== '' && (
                  <Button
                    appearance='secondary'
                    onClick={() => {
                      onClickSecondaryBtn && onClickSecondaryBtn()
                    }}
                    {...restProps.secondaryButtonProps}>
                    {secondaryButtonTitle}
                  </Button>
                )}

                {/*footer primary button*/}
                {primaryButtonTitle !== '' && (
                  <Button
                    appearance='primary'
                    onClick={() => {
                      onClickPrimaryBtn && onClickPrimaryBtn()
                    }}
                    {...restProps.primaryButtonProps}>
                    {primaryButtonTitle}
                  </Button>
                )}
              </div>
            )}
          </motion.div>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default Modal

Modal.defaultProps = {
  animationType: 'fade',
  children: undefined,
  className: undefined,
  customFooterButtonTitle: 'Lorem',
  headerTitle: 'Lorem ipsum',
  isActive: true,
  isConfirmationModal: false,
  isCustomFooter: false,
  isFooterShow: true,
  isHeaderShow: true,
  isInline: false,
  isSeparator: true,
  onClickCustomFooterBtn: undefined,
  onClickPrimaryBtn: undefined,
  onClickSecondaryBtn: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Lorem',
  secondaryButtonTitle: 'Lorem',
}
