import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteClientProfilePictureInterface {
  Id?: string
}

export const useDeleteClientProfilePicture = () => {
  const mutate = useMutation(({ Id }: DeleteClientProfilePictureInterface) => {
    return deleteAPI(`/ClientProfile/${Id}/Avatar`)
  })

  return mutate
}

export default useDeleteClientProfilePicture
