import { FC } from 'react'
import CN from 'classnames'

import { Modal } from 'components/molecules'

export interface ConfirmationModalProps {
  [x: string]: any
  className?: string | undefined
  isActive?: boolean | undefined
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string | undefined
  secondaryButtonTitle?: string | undefined
  state: 'error' | 'success' | 'warning'
  errorStateIcon?: string | undefined
  successStateIcon?: string | undefined
  warningStateIcon?: string | undefined
  subTitle?: string | undefined
  title?: string | undefined
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  className,
  isActive,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onOverlayClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  state,
  errorStateIcon,
  successStateIcon,
  warningStateIcon,
  subTitle,
  title,
  ...restProps
}: ConfirmationModalProps) => {
  const IconBackgroundClassName = CN(
    'w-12 h-12 flex justify-center items-center rounded-full border-[4px]',
    {
      'bg-R-500 border-R-200': state === 'error',
      'bg-G-500 border-G-200': state === 'success',
      'bg-Y-500 border-Y-200': state === 'warning',
    },
  )

  const IconClassName = 'text-white text-[24px] leading-none'
  return (
    <div {...restProps}>
      <Modal
        {...restProps}
        isActive={isActive}
        className={className}
        isHeaderShow={false}
        isConfirmationModal={true}
        primaryButtonTitle={primaryButtonTitle}
        secondaryButtonTitle={secondaryButtonTitle}
        onOverlayClick={() => {
          onOverlayClick && onOverlayClick()
        }}
        onClickPrimaryBtn={() => {
          onClickPrimaryBtn && onClickPrimaryBtn()
        }}
        onClickSecondaryBtn={() => {
          onClickSecondaryBtn && onClickSecondaryBtn()
        }}>
        <div className='flex w-full px-6 py-10 border-t-8 rounded border-S-300 gap-x-6'>
          <div className={IconBackgroundClassName}>
            {state == 'error' && (
              <i className={CN(IconClassName, errorStateIcon)}></i>
            )}
            {state == 'success' && (
              <i className={CN(IconClassName, successStateIcon)}></i>
            )}
            {state == 'warning' && (
              <i className={CN(IconClassName, warningStateIcon)}></i>
            )}
          </div>

          <div className='flex flex-col gap-y-1'>
            <span className='font-bold text-base text-N-800'>{title}</span>

            <span className='font-regular text-small text-N-500'>
              {subTitle}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

ConfirmationModal.defaultProps = {
  className: undefined,
  primaryButtonTitle: '',
  secondaryButtonTitle: '',
  errorStateIcon: 'ri-delete-bin-line',
  successStateIcon: 'ri-checkbox-circle-line',
  warningStateIcon: 'ri-error-warning-line',
  subTitle: '',
  title: '',
}

export default ConfirmationModal
