import { useMutation } from 'react-query'
import { get, postAPI } from 'framework/api/http'

interface GetStockOnHandListInterface {
  search?: string
  skip?: number
  status?: string
  take?: number
  warehouse?: string
}

export const useGetStockOnHandList = () => {
  const mutate = useMutation(
    ({
      search,
      skip,
      status,
      take,
      warehouse,
    }: GetStockOnHandListInterface) => {
      return postAPI(`/StockOnHand`, {
        take,
        skip,
        searchKeyword: search === '' ? null : search,
        warehouse: warehouse === 'All' ? null : warehouse,
        status: status === 'All' ? null : status,
      })
    },
  )

  return mutate
}

export default useGetStockOnHandList
