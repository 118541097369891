import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'

interface PasswordStrengthBarProps {
  className?: string | undefined
  customLabel?: string | undefined
  customLabelClassName?: string | undefined
  isCustomStyles?: boolean
  isForPassword?: boolean
  isLabel?: boolean
  progressCount?: number
}

export const PasswordStrengthBar: FC<PasswordStrengthBarProps> = ({
  className,
  customLabel,
  customLabelClassName,
  isCustomStyles,
  isForPassword,
  isLabel,
  progressCount,
  ...restProps
}: PasswordStrengthBarProps) => {
  const defaultValue = () => {
    if (Number(progressCount) > 80) {
      return 'Excellent'
    } else if (Number(progressCount) > 49) {
      return isForPassword ? 'Weak' : 'Strong'
    } else if (Number(progressCount) > 24) {
      return isForPassword ? 'Weak' : 'Average'
    } else {
      return 'Weak'
    }
  }
  useEffect(() => {
    const test = () => {
      if (Number(progressCount) > 80) {
        return 'Excellent'
      } else if (Number(progressCount) > 49) {
        return isForPassword ? 'Weak' : 'Strong'
      } else if (Number(progressCount) > 24) {
        return isForPassword ? 'Weak' : 'Average'
      } else {
        return 'Weak'
      }
    }
    setProgressStatus(test)
  }, [progressCount])
  const [progressStatus, setProgressStatus] = useState<any>(defaultValue)

  /** Parent class styles */
  const ParentClassNames = !isCustomStyles
    ? CN('flex justify-between items-center')
    : className

  /** Progress Bar styles  */
  const ProgressBarClassNames = !isCustomStyles
    ? CN('flex h-1 ', {
        'bg-R-500 rounded-l': progressStatus === 'Weak',
        'bg-Y-500 rounded-l': progressStatus === 'Average',
        'bg-[#205387] rounded-l': progressStatus === 'Strong',
        'bg-G-500 rounded': progressStatus === 'Excellent',
      })
    : className

  return (
    <div className={ParentClassNames} {...restProps} key={progressCount}>
      {/** Progress Bar  */}
      <div className='w-full rounded bg-[#EEF3F8]'>
        <div
          className={ProgressBarClassNames}
          style={{ width: `${Number(progressCount)}%` }}></div>
      </div>

      {!customLabel && isLabel && (
        <div
          className={CN('text-x-small font-medium pl-4', {
            'text-R-500': progressStatus === 'Weak',
            'text-Y-500': progressStatus === 'Average',
            'text-[#205387]': progressStatus === 'Strong',
            'text-G-500': progressStatus === 'Excellent',
          })}>
          {progressStatus}
        </div>
      )}

      {customLabel && isLabel && (
        <div
          className={CN('text-x-small font-medium pl-4', customLabelClassName)}>
          {customLabel}
        </div>
      )}
    </div>
  )
}

export default PasswordStrengthBar

PasswordStrengthBar.defaultProps = {
  className: undefined,
  customLabel: undefined,
  customLabelClassName: undefined,
  isCustomStyles: undefined,
  isForPassword: false,
  isLabel: false,
  progressCount: 0,
}
