/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import inboundOrderLineReportIcon from 'assets/images/report-icons/inboundOrderLineReportIcon.png'
import inboundOrderLineReportIconHovered from 'assets/images/report-icons/inboundOrderLineReportIconHovered.png'
import inboundOrderReportIcon from 'assets/images/report-icons/inboundOrderReportIcon.png'
import inboundOrderReportIconHovered from 'assets/images/report-icons/inboundOrderReportIconHovered.png'
import outboundOrderLineReportIcon from 'assets/images/report-icons/outboundOrderLineReportIcon.png'
import outboundOrderLineReportIconHovered from 'assets/images/report-icons/outboundOrderLineReportIconHovered.png'
import outboundOrderReportIcon from 'assets/images/report-icons/outboundOrderReportIcon.png'
import outboundOrderReportIconHovered from 'assets/images/report-icons/outboundOrderReportIconHovered.png'
import stockOnHandReportIcon from 'assets/images/report-icons/stockOnHandReportIcon.png'
import stockOnHandReportIconHovered from 'assets/images/report-icons/stockOnHandReportIconHovered.png'

export const Reports: FC = () => {
  const navigate = useNavigate()

  const reportList: any = [
    // {
    //   id: 1,
    //   name: 'Inbound Order Report',
    //   iconSrc: inboundOrderReportIcon,
    //   hoveredIconSrc: inboundOrderReportIconHovered,
    //   isShown: true,
    //   path: '/reports/inbound-order-report',
    // },
    {
      id: 2,
      name: 'Inbound Order Line Report',
      iconSrc: inboundOrderLineReportIcon,
      hoveredIconSrc: inboundOrderLineReportIconHovered,
      isShown: true,
      path: '/reports/inbound-order-line-report',
    },
    // {
    //   id: 3,
    //   name: 'Outbound Order Report',
    //   iconSrc: outboundOrderReportIcon,
    //   hoveredIconSrc: outboundOrderReportIconHovered,
    //   isShown: true,
    //   path: '/reports/outbound-order-report',
    // },
    // {
    //   id: 4,
    //   name: 'Outbound Order Line Report',
    //   iconSrc: outboundOrderLineReportIcon,
    //   hoveredIconSrc: outboundOrderLineReportIconHovered,
    //   isShown: true,
    //   path: '/reports/outbound-order-line-report',
    // },
    // {
    //   id: 5,
    //   name: 'Stock On Hand Report',
    //   iconSrc: stockOnHandReportIcon,
    //   hoveredIconSrc: stockOnHandReportIconHovered,
    //   isShown: true,
    //   path: '/reports/stock-on-hand-report',
    // },
  ]

  return (
    <div className='flex flex-col h-[70vh] w-full bg-white rounded-lg p-3 shadow-md'>
      <div className='w-full grid grid-cols-3 gap-x-3 gap-y-8'>
        {reportList?.map((item: any) => {
          if (item?.isShown === false) {
            return null
          } else {
            return (
              <div
                className='flex flex-col p-3 border border-N-200 rounded-lg items-center hover:bg-N-50 group cursor-pointer'
                key={item?.id}
                onClick={() => {
                  item?.path && navigate(item?.path)
                }}>
                <div className='w-20 h-20 flex justify-center items-center bg-N-50 group-hover:bg-P-50 rounded-xl'>
                  <img
                    src={item?.iconSrc}
                    alt='icon'
                    className='group-hover:hidden '
                  />
                  <img
                    src={item?.hoveredIconSrc}
                    alt='icon'
                    className='hidden group-hover:block'
                  />
                </div>
                <div className='text-[18px] leading-7 font-semibold text-N-800  group-hover:text-P-500 pt-1'>
                  {item?.name}
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default Reports
