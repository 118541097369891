import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    useDeleteClientProfilePicture,
    useGetCountryList,
    useGetStateListByCountryCode,
    useUpdateUserDetails,
    useUploadClientProfilePicture,
} from 'framework/api/methods'
import {setUserDetails} from 'store/reducers/user/userSlice'

import {
    Button,
    Dropdown,
    InputField,
    PageLoader,
    ProfileAvatar,
} from 'components/atoms'
import {
    ConfirmationModal,
    CustomizableEditProfilePictureModal,
    Toast,
} from 'components/molecules'
import {isLetters, isPhoneNumber} from 'utils'
import {isEmail} from 'utils/isEmail'

export const MyProfile: FC = () => {
    const {userDetails}: any = useSelector<any>((state) => state.user)

    const [showEditPhotoModal, setShowEditPhotoModal] = useState(false)
    const [imageUrl, setImageUrl] = useState<any>(undefined)
    const [files, setFiles] = useState<any>()
    const [isErrorField, setIsErrorField] = useState<any>({
        firstName: false,
        lastName: false,
        supportEmail: false,
        state: false,
        phoneNumber: false,
    })
    const notify = (props: any) => Toast(props)
    const dispatch: any = useDispatch()
    const [countryList, setCountryList] = useState([])

    const [stateList, setStateList] = useState([])

    const [isEditMode, setIsEditMode] = useState(false)
    const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] =
        useState(false)

    const [updatedUserInformation, setUpdatedUserInformation] = useState<any>({
        id: '',
        email: '',
        roleName: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        supportEmail: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postalCode: '',
        country: '',
        avatarUrl: '',
    })

    const {mutate: getCountryListMutate} = useGetCountryList()

    /** validate support email fields */
    const validateFormInputs = () => {
        setIsErrorField({
            ...isErrorField,
            // supportEmail:
            //   updatedUserInformation?.supportEmail !== null &&
            //   updatedUserInformation?.supportEmail !== ''
            //     ? !isEmail(updatedUserInformation?.supportEmail)
            //     : false,
            state: updatedUserInformation?.state?.label ? false : true,
            firstName: isLetters(updatedUserInformation?.firstName) ? false : true,
            lastName: isLetters(updatedUserInformation?.lastName) ? false : true,
            phoneNumber:
                updatedUserInformation?.phoneNumber !== null &&
                updatedUserInformation?.phoneNumber !== ''
                    ? !isPhoneNumber(updatedUserInformation?.phoneNumber)
                    : false,
        })

        const isAllValidFields =
            !!(
                // (updatedUserInformation?.supportEmail !== null &&
                // updatedUserInformation?.supportEmail !== '' ? isEmail(updatedUserInformation?.supportEmail) : true) &&
                updatedUserInformation?.state?.label &&
                isLetters(updatedUserInformation?.firstName) && isLetters(updatedUserInformation?.lastName) &&
                (
                    updatedUserInformation?.phoneNumber !== null &&
                    updatedUserInformation?.phoneNumber !== '' ? isPhoneNumber(updatedUserInformation?.phoneNumber) : true
                )
            )
        return isAllValidFields
    }

    /** Process the Get Country Data List */
    async function getCountryList() {
        getCountryListMutate(
            {id: 1},
            {
                onSuccess: ({data: getCountryListData}: any) => {
                    const countries = getCountryListData.countries.map((country: any) => {
                        return {
                            value: country.code,
                            label: country.name,
                        }
                    })
                    setCountryList(countries)
                },
                onError: ({response: {data: errData}}: any) => {
                    notify({
                        alertHeader: 'Error',
                        alertBody: errData.message
                            ? errData.message
                            : 'Something went wrong',
                        status: 'Error',
                    })
                },
            },
        )
    }

    /** API for  Get state list by country code */
    const {mutate: GetStateListByCountryCodeMutate} =
        useGetStateListByCountryCode()

    /** Process the Get state list by country code */
    async function GetStateListByCountryCode() {
        GetStateListByCountryCodeMutate(
            {code: updatedUserInformation?.country?.value},
            {
                onSuccess: ({data: getStateListData}: any) => {
                    const states = getStateListData.states.map((state: any) => {
                        return {
                            value: state.code,
                            label: state.name,
                        }
                    })
                    setStateList(states)
                },
                onError: ({response: {data: errData}}: any) => {
                    notify({
                        alertHeader: 'Error',
                        alertBody: errData.message
                            ? errData.message
                            : 'Something went wrong',
                        status: 'Error',
                    })
                },
            },
        )
    }

    /** API Call for Update user details */
    const {
        mutate: updateUserDetailsMutate,
        isLoading: updateUserDetailsIsLoading,
    } = useUpdateUserDetails()

    /** Process update agency profile data API call */
    async function updateUserDetails() {
        updateUserDetailsMutate(updatedUserInformation, {
            onSuccess: ({data: successData}: any) => {
                dispatch(setUserDetails(successData.clientProfile))
                notify({
                    alertHeader: 'Success',
                    alertBody: successData.message
                        ? successData.message
                        : 'Personal Information Updated Successfully',
                    status: 'Success',
                })
                setIsEditMode(false)
            },
            onError: ({response: {data: errData}}: any) => {
                notify({
                    alertHeader: 'Error',
                    alertBody: errData.message ? errData.message : 'Something went wrong',
                    status: 'Error',
                })
            },
        })
    }

    useEffect(() => {
        getCountryList()
    }, [])

    useEffect(() => {
        updatedUserInformation?.country?.value && GetStateListByCountryCode()
    }, [updatedUserInformation?.country?.value])

    useEffect(() => {
        setUpdatedUserInformation({
            ...updatedUserInformation,
            id: userDetails?.id,
            roleName: userDetails?.roleName,
            email: userDetails?.email,
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            phoneNumber: userDetails?.phoneNumber,
            // supportEmail: userDetails?.supportEmail,
            streetAddress: userDetails?.address?.lineOne,
            state: {
                value: userDetails?.address?.stateCode,
                label: userDetails?.address?.state || '',
            },
            suburb: userDetails?.address?.suburb,
            postalCode: userDetails?.address?.postCode,
            country: {
                value: userDetails?.address?.countryCode,
                label: userDetails?.address?.country,
            },
            avatarUrl: userDetails?.avatarUrl,
        })
        isEditMode &&
        setIsErrorField({
            ...isErrorField,
            // supportEmail: false,
            state: false,
            firstName: false,
            lastName: false,
            phoneNumber: false,
        })
    }, [userDetails, isEditMode])

    /** handle change save  */
    const handleChangeSaveClick = () => {
        const valid = validateFormInputs()
        /** check valid field */
        if (valid) {
            updateUserDetails()
        } else {
            notify({
                alertHeader: 'Invalid input',
                alertBody: 'The field contains invalid input',
                status: 'Warning',
            })
        }
    }

    /** upload profile picture api */
    const {
        mutate: uploadClientProfilePictureMutate,
        isLoading: uploadClientProfilePictureIsLoading,
    } = useUploadClientProfilePicture()

    /** Process the upload profile picture */
    async function uploadClientProfilePicture(Id: any, avatarObject: any) {
        uploadClientProfilePictureMutate(
            {
                Id,
                avatarObject,
            },
            {
                onSuccess: ({data: successData}: any) => {
                    notify({
                        alertHeader: 'Success',
                        alertBody: successData?.message
                            ? successData.message
                            : `You've successfully changed your profile picture.`,
                        status: 'Success',
                    })
                    setFiles(null)
                    setImageUrl(null)
                    setShowEditPhotoModal(false)
                    dispatch(setUserDetails(successData.clientProfile))
                },
                onError: ({response: {data: errData}}: any) => {
                    setShowEditPhotoModal(false)
                    notify({
                        alertHeader: 'Error!',
                        alertBody: errData?.message,
                        status: 'Error',
                    })
                },
            },
        )
    }

    /** API Call for upload profile picture using react-query */
    const handleUploadClientAvatar = (files: any) => {
        uploadClientProfilePicture(userDetails.id, files)
    }

    /** API Call for delete user avatar using react-query */
    const {
        mutate: deleteClientProfilePictureMutate,
        isLoading: deleteClientProfilePictureIsLoading,
    } = useDeleteClientProfilePicture()

    /** Process the Delete User Avatar */
    async function deleteClientProfilePicture(Id: string) {
        deleteClientProfilePictureMutate(
            {Id},
            {
                onSuccess: ({data: successData}: any) => {
                    notify({
                        alertHeader: 'Success',
                        alertBody: successData?.message
                            ? successData.message
                            : `You've successfully removed your profile picture.`,
                        status: 'Success',
                    })
                    setIsOpenDeleteConfirmationModal(false)
                    dispatch(setUserDetails({...userDetails, avatarUrl: null}))
                },
                onError: ({response: {data: errData}}: any) => {
                    notify({
                        alertHeader: 'Error!',
                        alertBody: errData?.message ? errData?.message : 'Error!',
                        status: 'Error',
                    })
                },
            },
        )
    }

    return (
        <div className='flex flex-col h-full w-full relative'>
            {(updateUserDetailsIsLoading ||
                uploadClientProfilePictureIsLoading ||
                deleteClientProfilePictureIsLoading) && <PageLoader/>}

            <div className='text-heading-3 font-semibold'>My Profile</div>

            {/*<div className='w-full justify-center items-center flex flex-col border border-N-200 rounded-lg px-5 py-3 bg-gradient-to-b from-P-100 from-55% to-white to-45% '>*/}
            {/*  <ProfileAvatar*/}
            {/*    isProfilePictureAvailable={*/}
            {/*      updatedUserInformation?.avatarUrl ? true : false*/}
            {/*    }*/}
            {/*    imageSource={updatedUserInformation?.avatarUrl || undefined}*/}
            {/*    imageContainerClassName='w-[132px] h-[132px]'*/}
            {/*    onAddClick={() => {*/}
            {/*      setShowEditPhotoModal(true)*/}
            {/*    }}*/}
            {/*    onDeleteClick={() => {*/}
            {/*      setIsOpenDeleteConfirmationModal(true)*/}
            {/*    }}*/}
            {/*    onEditClick={() => {*/}
            {/*      setShowEditPhotoModal(true)*/}
            {/*    }}*/}
            {/*  />*/}

            {/*  <div className='font-bold mt-4'>{userDetails?.fullName}</div>*/}
            {/*  <div className='text-small'>{userDetails?.roleName}</div>*/}
            {/*</div>*/}

            <div className='w-full justify-center items-center flex flex-col border border-N-200 rounded-lg px-5 py-3 mt-8'>
                <div className='flex w-full justify-between items-center'>
                    <div className='text-heading-3 font-semibold text-N-800'>
                        Personal Information
                    </div>

                    {isEditMode ? (
                        <div className='flex gap-x-1'>
                            <Button
                                iconOnly
                                size='md'
                                appearance='secondary'
                                onClick={() => {
                                    setIsEditMode(false)
                                }}>
                                <i className='ri-reply-line text-[24px] leading-none'/>
                            </Button>
                            <Button
                                iconBefore={'ri-save-line'}
                                size='md'
                                appearance='secondary'
                                onClick={() => {
                                    handleChangeSaveClick()
                                }}>
                                Save
                            </Button>
                        </div>
                    ) : (
                        <Button
                            iconBefore={'ri-edit-line'}
                            size='md'
                            appearance='secondary'
                            onClick={() => {
                                setIsEditMode(true)
                            }}>
                            Update
                        </Button>
                    )}
                </div>

                {isEditMode ? (
                    <div className='grid grid-cols-2 w-full gap-x-3 gap-y-6 mt-6'>
                        <InputField
                            label='First Name'
                            isRequired
                            value={updatedUserInformation?.firstName}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    firstName: e.target.value,
                                })
                            }}
                            isErrored={isErrorField?.firstName}
                            placeholder='Enter first name'
                        />

                        <InputField
                            label='Last Name'
                            isRequired
                            value={updatedUserInformation?.lastName}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    lastName: e.target.value,
                                })
                            }}
                            isErrored={isErrorField?.lastName}
                            placeholder='Enter first name'
                        />

                        <InputField
                            label='Contact Email'
                            value={updatedUserInformation?.email}
                            isDisabled
                            placeholder='Enter contact email'
                        />

                        {/*<InputField*/}
                        {/*  label='Support Email'*/}
                        {/*  value={updatedUserInformation?.supportEmail}*/}
                        {/*  onChange={(e: any) => {*/}
                        {/*    setUpdatedUserInformation({*/}
                        {/*      ...updatedUserInformation,*/}
                        {/*      supportEmail: e.target.value,*/}
                        {/*    })*/}
                        {/*  }}*/}
                        {/*  isErrored={isErrorField?.supportEmail}*/}
                        {/*  placeholder='Enter support email'*/}
                        {/*/>*/}

                        <InputField
                            label='Phone Number'
                            value={updatedUserInformation?.phoneNumber}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    phoneNumber: e.target.value,
                                })
                            }}
                            isErrored={isErrorField?.phoneNumber}
                            placeholder='Enter phone number'
                        />

                        <InputField
                            label='Street Address'
                            value={updatedUserInformation?.streetAddress}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    streetAddress: e.target.value,
                                })
                            }}
                            placeholder='Enter street address'
                        />

                        <InputField
                            label='Suburb'
                            value={updatedUserInformation?.suburb}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    suburb: e.target.value,
                                })
                            }}
                            placeholder='Enter Suburb'
                        />

                        <Dropdown
                            label='State'
                            isRequired
                            value={updatedUserInformation?.state}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    state: e,
                                })
                            }}
                            options={stateList}
                            isErrored={isErrorField?.state}
                        />

                        <InputField
                            label='Postal Code'
                            value={updatedUserInformation?.postalCode}
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    postalCode: e.target.value,
                                })
                            }}
                            placeholder='Enter Postal Code'
                        />

                        <Dropdown
                            label='Country'
                            value={updatedUserInformation?.country}
                            menuPlacement='top'
                            onChange={(e: any) => {
                                setUpdatedUserInformation({
                                    ...updatedUserInformation,
                                    country: e,
                                    state: '',
                                })
                            }}
                            options={countryList}
                        />
                    </div>
                ) : (
                    <div className='grid grid-cols-2 w-full gap-x-3 gap-y-6 mt-6'>
                        <div className='flex flex-col'>
                            <span className='text-N-500'>First Name</span>
                            <span className='mt-1 font-medium text-N-800'>
                              {updatedUserInformation?.firstName ? updatedUserInformation?.firstName : 'N/A'}
                            </span>
                        </div>

                        <div className='flex flex-col'>
                            <span className='text-N-500'>Last Name</span>
                            <span className='mt-1 font-medium text-N-800'>
                            {updatedUserInformation?.lastName ? updatedUserInformation?.lastName : 'N/A'}
                          </span>
                        </div>

                        <div className='flex flex-col'>
                            <span className='text-N-500'>Contact Email</span>
                            <a
                                href={`mailto:${updatedUserInformation?.email}`}
                                className='mt-1 font-medium text-N-800'>
                                {updatedUserInformation?.email ? updatedUserInformation?.email : 'N/A'}
                            </a>
                        </div>

                        {/*<div className='flex flex-col'>*/}
                        {/*  <span className='text-N-500'>Support Email</span>*/}
                        {/*  <a*/}
                        {/*      href={*/}
                        {/*        updatedUserInformation?.supportEmail*/}
                        {/*            ? `mailto:${updatedUserInformation?.supportEmail}`*/}
                        {/*            : undefined*/}
                        {/*      }*/}
                        {/*      className='mt-1 font-medium text-N-800 break-all'>*/}
                        {/*    {updatedUserInformation?.supportEmail*/}
                        {/*        ? updatedUserInformation?.supportEmail*/}
                        {/*        : 'N/A'}*/}
                        {/*  </a>*/}
                        {/*</div>*/}

                        <div className='flex flex-col'>
                            <span className='text-N-500'>Phone Number</span>
                            <span className='mt-1 font-medium text-N-800'>
                {updatedUserInformation?.phoneNumber
                    ? updatedUserInformation?.phoneNumber
                    : 'N/A'}
              </span>
                        </div>

                        <div className='flex flex-col'>
                            <span className='text-N-500'>Address</span>
                            <span className='mt-1 font-medium text-N-800'>
                {updatedUserInformation?.streetAddress &&
                    `${updatedUserInformation?.streetAddress}, `}

                                {updatedUserInformation?.suburb &&
                                    `${updatedUserInformation?.suburb}, `}

                                {updatedUserInformation?.state?.label &&
                                    `${updatedUserInformation?.state?.label}, `}

                                {updatedUserInformation?.country?.label &&
                                    `${updatedUserInformation?.country?.label}, `}

                                {updatedUserInformation?.postalCode &&
                                    `${updatedUserInformation?.postalCode}`}
              </span>
                        </div>
                    </div>
                )}
            </div>

            {/* add new photo section */}
            <CustomizableEditProfilePictureModal
                imageSource={updatedUserInformation?.avatarUrl}
                isActive={showEditPhotoModal}
                onHeaderCloseButtonClick={() => {
                    setShowEditPhotoModal(false)
                }}
                onClickSecondaryBtn={() => {
                    setShowEditPhotoModal(false)
                }}
                onOverlayClick={() => {
                    setShowEditPhotoModal(false)
                }}
                onClickPrimaryBtn={() => {
                    handleUploadClientAvatar(files)
                }}
                primaryButtonIsLoading={uploadClientProfilePictureIsLoading}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                setFiles={setFiles}
                files={files}
                headerTitle={
                    updatedUserInformation?.avatarUrl
                        ? 'Change profile photo'
                        : 'Add profile photo'
                }
            />

            <ConfirmationModal
                className='w-[466px]'
                isActive={isOpenDeleteConfirmationModal}
                state='error'
                title='Remove Profile Photo?'
                subTitle='Are you sure you want to proceed?'
                primaryButtonTitle={`YES, I'M SURE`}
                secondaryButtonTitle='CLOSE'
                onOverlayClick={() => {
                    setIsOpenDeleteConfirmationModal(false)
                }}
                onClickPrimaryBtn={() => {
                    deleteClientProfilePicture(userDetails?.id)
                }}
                onClickSecondaryBtn={() => {
                    setIsOpenDeleteConfirmationModal(false)
                }}
            />
        </div>
    )
}

MyProfile.defaultProps = {
    className: undefined,
}

export default MyProfile
