import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import bdlLogo from 'assets/images/bdl-logo.svg'
import CN from 'classnames'

export interface HeaderProps {
  className?: string | undefined
  [x: string]: any
}

export const Header: FC<HeaderProps> = ({
  className,
  ...restProps
}: HeaderProps) => {
  const HeaderClasses = CN(
    'flex justify-between items-center w-full border-b-[1px] border-[#E5E7EB] py-2 relative px-4',
    className,
    {},
  )

  return (
    <div className={HeaderClasses} {...restProps}>
      <img src={bdlLogo} alt='' className='object-contain h-8 ml-2' />
    </div>
  )
}

Header.defaultProps = {
  className: undefined,
}

export default Header
