/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useState } from 'react'
import defaultPic from 'assets/images/default-pic.svg'
import CN from 'classnames'

export interface ProfileAvatarProps {
  className?: string | undefined
  imageContainerClassName?: string | undefined
  imageSource?: string | undefined
  isProfilePictureAvailable?: boolean | undefined
  onAddClick?: () => void
  onDeleteClick?: () => void
  onEditClick?: () => void
  [x: string]: any
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  className,
  imageContainerClassName,
  imageSource,
  isProfilePictureAvailable,
  onAddClick,
  onDeleteClick,
  onEditClick,
  ...restProps
}: ProfileAvatarProps) => {
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)
  const ProfileAvatarClasses = CN(`profile-avatar`, className)

  const ImageContainerClasses = CN(
    `flex items-center justify-center rounded-full overflow-hidden border border-N-100`,
    imageContainerClassName,
    {
      'object-contain bg-Gray-100': imageSource === undefined,
      'object-fill': imageSource !== undefined,
    },
  )

  return (
    <div className={ProfileAvatarClasses} {...restProps}>
      <div className='flex flex-col items-center relative'>
        <div className={ImageContainerClasses}>
          {imageSource === undefined ? (
            <img src={defaultPic} alt='' className='object-contain' />
          ) : (
            <img
              src={imageSource}
              alt=''
              className='flex w-full h-auto object-contain rounded-full bg-white'
            />
          )}
        </div>

        {/** add new button for upload profile picture */}
        {!isProfilePictureAvailable && (
          <div className='absolute bottom-0 left-2/3'>
            <div className='bg-white rounded-full shadow-md overflow-hidden'>
              <i
                className='ri-add-fill flex justify-center items-center text-[18px] h-[18px] w-[18px] p-[20px] text-P-500 hover:bg-N-50  cursor-pointer'
                onClick={onAddClick}
              />
            </div>
          </div>
        )}

        {/** add new button for upload profile picture */}
        {isProfilePictureAvailable && (
          <>
            <div className='absolute bottom-0 left-2/3'>
              <div className='bg-white rounded-full shadow-md overflow-hidden'>
                <i
                  className='ri-pencil-line flex justify-center items-center text-[18px] h-[18px] w-[18px] p-[20px] text-P-500 hover:bg-N-50 cursor-pointer'
                  onClick={() => {
                    setShowDropDownMenu(!showDropDownMenu)
                  }}
                />
              </div>
            </div>
            {showDropDownMenu && (
              <div className='flex absolute w-[160px] flex-col bottom-[-92px] justify-end right-[-10px] bg-white d py-1 rounded-md shadow-md z-10'>
                <span
                  className='text-N-800 px-3 py-2 hover:bg-N-100 active:bg-GrNay-200 border-b border-b-N-100 cursor-pointer'
                  onClick={() => {
                    setShowDropDownMenu(false)
                    onEditClick && onEditClick()
                  }}>
                  Change photo
                </span>
                <span
                  className='text-R-500 px-3 py-2 hover:bg-N-100 active:bg-N-200 cursor-pointer'
                  onClick={() => {
                    setShowDropDownMenu(false)
                    onDeleteClick && onDeleteClick()
                  }}>
                  Remove photo
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

ProfileAvatar.defaultProps = {
  imageContainerClassName: 'w-[132px] h-[132px]',
  imageSource: undefined,
  isProfilePictureAvailable: true,
  onAddClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
}

export default ProfileAvatar
