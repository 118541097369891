import { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CN from 'classnames'
import { useResetPassword } from 'framework/api/methods'

import { Button, InputField, PasswordStrengthBar } from 'components/atoms'
import { Toast } from 'components/molecules'
import { Alert } from 'components/molecules/Toast/Alert'
import { passwordStrengthCalculate } from 'utils'

export interface ResetPasswordProps {
  [x: string]: any
}

export const ResetPassword: FC<ResetPasswordProps> = ({
  ...restProps
}: ResetPasswordProps) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const notify = (props: any) => Toast(props)

  const [dataObject, setDataObject] = useState({
    username: '',
    resetToken: '',
    password: '',
    confirmPassword: '',
  })

  const [passwordStrength, setPasswordStrength] = useState(0)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  /** Reset password User API Call ------> Mutate */
  const { mutate: resetPasswordMutate, isLoading: resetPasswordIsLoading } =
    useResetPassword()

  /** Process the reset password submission */
  async function resetPassword() {
    resetPasswordMutate(
      {
        username: searchParams.get('user') || '',
        resetToken: searchParams.get('token') || '',
        password: dataObject.confirmPassword,
      },
      {
        onSuccess: () => {
          navigate('/auth/success-reset-password')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle reset password button */
  const handleResetPasswordButton = () => {
    resetPassword()
  }
  return (
    <div className='flex flex-col justify-center' {...restProps}>
      <span className='flex justify-center items-center font-bold text-heading-1 text-N-700 pt-[60px]'>
        Reset Your Password
      </span>

      <span className='flex justify-center items-center font-medium text-base text-N-700 pt-2'>
        It's time to change your password to a new one.
      </span>

      <InputField
        type={showNewPassword ? 'text' : 'password'}
        label='New Password'
        placeholder='••••••••'
        className='pt-8 w-full pb-2'
        value={dataObject.password}
        onChange={(e: any) => {
          setDataObject({
            ...dataObject,
            password: e.target.value,
          })
          setPasswordStrength(passwordStrengthCalculate(e.target.value))
        }}
        leadingIcon={
          dataObject.password !== '' ? (
            <i
              className={CN('hover:cursor-pointer', {
                'ri-eye-off-line': showNewPassword == false,
                'ri-eye-line': showNewPassword == true,
              })}
            />
          ) : undefined
        }
        leadingIconOnClick={() => {
          setShowNewPassword(!showNewPassword)
        }}
      />

      <PasswordStrengthBar
        isLabel
        progressCount={dataObject?.password === '' ? 0 : passwordStrength}
        isForPassword
      />

      <InputField
        type={showConfirmPassword ? 'text' : 'password'}
        label='Confirm New Password'
        placeholder='••••••••'
        className='pt-6 w-full'
        onChange={(e: any) => {
          setDataObject({
            ...dataObject,
            confirmPassword: e.target.value,
          })
        }}
        leadingIcon={
          dataObject.confirmPassword !== '' ? (
            <i
              className={CN('hover:cursor-pointer', {
                'ri-eye-off-line': showConfirmPassword == false,
                'ri-eye-line': showConfirmPassword == true,
              })}
            />
          ) : undefined
        }
        leadingIconOnClick={() => {
          setShowConfirmPassword(!showConfirmPassword)
        }}
      />

      <Button
        appearance='primary'
        className='mt-6 w-full'
        disabled={
          dataObject.password === '' ||
          dataObject.confirmPassword === '' ||
          dataObject.password !== dataObject.confirmPassword ||
          passwordStrength < 81
            ? true
            : false
        }
        isLoading={resetPasswordIsLoading}
        onClick={handleResetPasswordButton}>
        RESET MY PASSWORD
      </Button>
    </div>
  )
}

ResetPassword.defaultProps = {}

export default ResetPassword
