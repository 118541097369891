/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { StockOnHandStatusBadge } from 'components/molecules'

export const StockOnHandListColumn = () => {
  const COLUMNS = [
    {
      id: 'productCode',
      Header: 'PRODUCT CODE',
      accessor: 'productCode',
      forSorting: 'productCode',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: false,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'productDescription',
      Header: 'PRODUCT DESCRIPTION',
      accessor: 'productDescription',
      forSorting: 'productDescription',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'warehouseCode',
      Header: 'WAREHOUSE CODE',
      accessor: 'warehouseCode',
      forSorting: 'warehouseCode',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'quntity',
      Header: 'QUANTITY',
      accessor: 'quntity',
      forSorting: 'quntity',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'allocated',
      Header: 'ALLOCATED',
      accessor: 'allocated',
      forSorting: 'allocated',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'available',
      Header: 'AVAILABLE',
      accessor: 'available',
      forSorting: 'available',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'status',
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[150px]'>
          {row.value && <StockOnHandStatusBadge orderStatus={row.value} />}
        </div>
      ),
    },
    {
      id: 'barcode',
      Header: 'BAR CODE',
      accessor: 'barcode',
      forSorting: 'barcode',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
  ]

  return COLUMNS
}

export default StockOnHandListColumn
