import React from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'

import { AuthRequired } from './components/auth/AuthRequired'
import { routes } from './routes/routes'

const App: React.FC = () => {
  // const routes = useRoutes()

  /* Render component with or without Layout */
  const renderWithLayout = ({ Component, Layout, ...restProps }: any) => {
    if (Layout) {
      return (
        <Layout {...restProps}>
          <Component />
        </Layout>
      )
    }
    return <Component {...restProps} />
  }

  /* Rendering routes */
  const renderComponent = ({ isSecured, path, ...restProps }: any) => {
    if (isSecured) {
      return (
        <AuthRequired path={path}>
          {renderWithLayout({ ...restProps })}
        </AuthRequired>
      )
    } else {
      return renderWithLayout({ ...restProps })
    }
  }

  /* Iterate through Routes */
  const routeRender = (
    <Routes>
      {routes.map(
        (
          {
            redirectTo,
            path,
            layout: Layout,
            component: Component,
            isSecured,
            hasSidebar = true,
            heading,
            isBackButton,
            subHeading,
            hasSubHeading = true,
            hasSubFooter = false,
            hasPermissions = false,
          }: any,
          key,
        ) => {
          /* If redirectTo is defined, render a Redirect component */
          if (redirectTo) {
            return (
              <Route
                key={key}
                path={path}
                element={<Navigate to={redirectTo} />}
              />
            )
          }

          /* Render Route component */
          return (
            <Route
              path={path}
              key={key}
              element={renderComponent({
                path,
                Component,
                Layout,
                isSecured,
                hasSidebar,
                heading,
                isBackButton,
                subHeading,
                hasSubHeading,
                hasSubFooter,
                hasPermissions,
              })}
            />
          )
        },
      )}
    </Routes>
  )

  return <Router>{routeRender}</Router>
}

export default App
