import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface StockOnHandExportAsCSVInterface {
  search?: string
  status?: string
  warehouse?: string
}

export const useStockOnHandExportAsCSV = () => {
  const mutate = useMutation(
    ({ search, status, warehouse }: StockOnHandExportAsCSVInterface) => {
      return postAPI(`/StockOnHand/Csv`, {
        searchKeyword: search === '' ? null : search,
        warehouse: warehouse === 'All' ? null : warehouse,
        status: status === 'All' ? null : status,
      })
    },
  )

  return mutate
}

export default useStockOnHandExportAsCSV
