export const passwordStrengthCalculate = (password: any) => {
  let strength = 0

  /** for check capital letters contain */
  if (/(?=.*[A-Z])/.test(password)) {
    strength = strength + 20
  }

  /** for check simple letters contain */
  if (/(?=.*[a-z])/.test(password)) {
    strength = strength + 20
  }

  /** for check digits contain */
  if (/\d/.test(password)) {
    strength = strength + 20
  }

  /** for check special characters contain */
  // eslint-disable-next-line no-useless-escape
  if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
    strength = strength + 20
  }

  /** for check password length is more than 8 digits */
  if (/.{8,}/.test(password)) {
    strength = strength + 20
  }

  return strength
}

export default passwordStrengthCalculate
