import { FC } from 'react'
import CN from 'classnames'

interface AlertProps {
  icon?: string | undefined
  alertBody?: string | undefined
  alertHeader?: string | undefined
  className?: string | undefined
  isCloseIcon?: boolean
  isCustomStyles?: boolean
  isIcon?: boolean
  onCloseIconClick?: () => void
  status?: 'Info' | 'Success' | 'Warning' | 'Error'
}

export const Alert: FC<AlertProps> = ({
  icon,
  alertBody,
  alertHeader,
  className,
  isCloseIcon,
  isCustomStyles,
  isIcon,
  onCloseIconClick,
  status,
  ...restProps
}: AlertProps) => {
  /** Alert main styles */
  const AlertMainClassName = !isCustomStyles
    ? CN('flex w-full p-4 text-white rounded shadow-md', className, {
        'bg-S-700': status === 'Info',
        'bg-G-500': status === 'Success',
        'bg-Y-500': status === 'Warning',
        'bg-R-400': status === 'Error',
      })
    : className

  /** icon styles */
  const IconClassName = CN(
    'flex justify-center items-center w-[40px] h-[40px] rounded-full',
    {
      'bg-S-800': status === 'Info',
      'bg-G-600': status === 'Success',
      'bg-Y-600': status === 'Warning',
      'bg-R-600': status === 'Error',
    },
  )

  /** custom icon styles */
  const CustomIconClassName = CN(icon, 'text-[24px] text-white', {
    'ri-information-line': status === 'Info',
    'ri-checkbox-circle-line': status === 'Success',
    'ri-error-warning-line': status === 'Warning',
    'ri-close-circle-line': status === 'Error',
  })

  return (
    <div className={AlertMainClassName} {...restProps}>
      {/* close icon */}
      {isCloseIcon && (
        <i
          className='pr-[16px] ri-close-fill text-[16px]'
          onClick={onCloseIconClick}
          aria-hidden={true}></i>
      )}

      {/* body container */}
      <div className='body-container flex justify-between w-full'>
        <div className='body-left-container flex flex-col'>
          {/* header inside body container*/}
          {alertHeader && (
            <div className='font-bold text-small text-white w-full'>
              {alertHeader}
            </div>
          )}

          {/* detailed description text inside body container*/}
          {alertBody && (
            <div className='font-Regular text-small text-white w-full pt-1'>
              {alertBody}
            </div>
          )}
        </div>

        {/* icon inside body container*/}
        {isIcon && (
          <div className={IconClassName}>
            <i className={CustomIconClassName}></i>
          </div>
        )}
      </div>
    </div>
  )
}

export default Alert

Alert.defaultProps = {
  icon: '',
  alertBody: '',
  alertHeader: '',
  className: undefined,
  isCloseIcon: true,
  isCustomStyles: false,
  isIcon: true,
  onCloseIconClick: undefined,
  status: 'Info',
}
