import {FC, useState} from 'react'
import {useSelector} from 'react-redux'

export const Partner: FC = () => {
    const {userDetails}: any = useSelector<any>((state) => state.user)

    return (
        <div className='flex flex-col h-full w-full relative'>
            <div className='text-heading-3 font-semibold'>Partner</div>

            <div className='w-full justify-center items-center flex flex-col border border-N-200 rounded-lg px-5 py-3 mt-8'>
                <div className='flex w-full justify-between items-center'>
                    <div className='text-heading-3 font-semibold text-N-800'>
                        Partner Information
                    </div>
                </div>

                <div className='grid grid-cols-2 w-full gap-x-3 gap-y-6 mt-6'>
                    <div className='flex flex-col'>
                        <span className='text-N-500'>Partner Name</span>
                        <span className='mt-1 font-medium text-N-800'>
                            {userDetails?.partnerName ? userDetails?.partnerName : 'N/A'}
                        </span>
                    </div>

                    <div className='flex flex-col'>
                        <span className='text-N-500'>Partner Code</span>
                        <span className='mt-1 font-medium text-N-800'>
                            {userDetails?.partnerCode ? userDetails?.partnerCode : 'N/A'}
                      </span>
                    </div>

                    <div className='flex flex-col'>
                        <span className='text-N-500'>Support Email</span>
                        <span className='mt-1 font-medium text-N-800'>
                            {userDetails?.partnerSupportEmail ? userDetails?.partnerSupportEmail : 'N/A'}
                      </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

Partner.defaultProps = {
    className: undefined,
}

export default Partner
