import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForgetPassword } from 'framework/api/methods'

import { Button, InputField } from 'components/atoms'
import { Toast } from 'components/molecules'
import { isEmail } from 'utils/isEmail'

export interface ForgotPasswordProps {
  [x: string]: any
}

export const ForgotPassword: FC<ForgotPasswordProps> = ({
  ...restProps
}: ForgotPasswordProps) => {
  const navigate = useNavigate()

  const notify = (props: any) => Toast(props)

  const [emailFieldValue, setEmailFieldValue] = useState('')
  const [isEmailErrorField, setIsEmailErrorField] = useState(false)
  const [isForgetPasswordBtnDisabled, setIsForgetPasswordBtnDisabled] =
    useState(false)

  /** validate input fields */
  const validateFormInputs = () => {
    setIsEmailErrorField(isEmail(emailFieldValue) ? false : true)

    const isAllValidFields = isEmail(emailFieldValue)
    return isAllValidFields
  }

  /** forget password API Call ------> Mutate */
  const { mutate: forgetPasswordMutate, isLoading: forgetPasswordIsLoading } =
    useForgetPassword()

  /** Process the Forget password submission */
  async function forgetPassword(email: any) {
    forgetPasswordMutate(
      {
        username: email,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: 'Success',
            alertBody: successData?.message
              ? successData?.message
              : 'Password reset link sent successfully',
            status: 'Success',
          })
          setIsForgetPasswordBtnDisabled(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle reset password  */
  const handleResetPasswordClick = () => {
    const valid = validateFormInputs()
    /** check valid field */
    if (valid) {
      forgetPassword(emailFieldValue)
    } else {
      notify({
        alertHeader: 'Invalid input',
        alertBody: 'Enter a valid email address',
        status: 'Warning',
      })
    }
  }

  return (
    <div className='flex flex-col justify-center' {...restProps}>
      <span className='flex justify-center items-center font-bold text-heading-1 text-N-700 pt-[60px]'>
        Forgot Password?
      </span>

      <span className='flex justify-center items-center font-medium text-base text-N-700 text-center pt-2'>
        Don’t worry, We will send you a password reset link right away. Check
        your mailbox and click on the reset link to get a new password.
      </span>

      <InputField
        type='text'
        className='pt-8 w-full'
        isErrored={isEmailErrorField}
        placeholder='Type in your email address'
        value={emailFieldValue}
        onChange={(e: any) => {
          setEmailFieldValue(e.target.value)
        }}
      />

      <Button
        appearance='primary'
        className='mt-6 w-full'
        disabled={!emailFieldValue || isForgetPasswordBtnDisabled}
        isLoading={forgetPasswordIsLoading}
        onClick={() => {
          handleResetPasswordClick()
        }}>
        SEND PASSWORD RESET LINK
      </Button>

      <Button
        appearance='secondary'
        className='mt-2 w-full'
        disabled={false}
        isLoading={false}
        iconBefore={'ri-arrow-left-line text-[24px]'}
        onClick={() => {
          navigate('/auth/login')
        }}>
        BACK TO LOGIN
      </Button>
    </div>
  )
}

ForgotPassword.defaultProps = {}

export default ForgotPassword
