import React, { FC } from 'react'
import CN from 'classnames'
import { AVAILABLE, UNAVAILABLE } from 'static-data/stockOnHandStatus'

import { Badge } from 'components/atoms'
import { capitalizeAll } from 'utils'

interface StockOnHandStatusBadgeProps {
  className?: string | undefined
  orderStatus?: string | undefined
}

export const StockOnHandStatusBadge: FC<StockOnHandStatusBadgeProps> = ({
  className,
  orderStatus,
  ...restProps
}: StockOnHandStatusBadgeProps) => {
  /** main badge styles */
  const OrderStatusBadgeClassName = CN(
    '',
    {
      '!bg-R-400': orderStatus === AVAILABLE,
      '!bg-G-400': orderStatus === UNAVAILABLE,
    },
    className,
  )

  return (
    <Badge
      className={OrderStatusBadgeClassName}
      isRounded
      type='default'
      {...restProps}>
      {capitalizeAll(orderStatus)}
    </Badge>
  )
}

export default StockOnHandStatusBadge

StockOnHandStatusBadge.defaultProps = {
  className: undefined,
  orderStatus: undefined,
}
