import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export interface AuthRequiredProps {
  [x: string]: any
}

export const AuthRequired: FC<AuthRequiredProps> = ({
  children,
}: AuthRequiredProps) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!token || token === 'undefined' || token === '') {
      // Redirect user to login page if Token doesn't exist
      navigate('/auth/login')
    }
  }, [localStorage])

  return children
}

export default AuthRequired
