import { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetStockOnHandList,
  useGetWarehouseList,
  useStockOnHandExportAsCSV,
  useStockOnHandReportExportAsExcel,
} from 'framework/api/methods'
import { AVAILABLE, UNAVAILABLE } from 'static-data/stockOnHandStatus'

import { Button, Dropdown } from 'components/atoms'
import { TableHeader, UITable } from 'components/common/UITable'
import { Toast } from 'components/molecules'
import { CustomizeColumModal } from 'components/molecules/CustomizeColumModal/CustomizeColumModal'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

import { StockOnHandListColumn } from './StockOnHandListColumn'

export const StockOnHandList: FC = () => {
  const orderStatusList = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: capitalizeFirstLetter(AVAILABLE),
      value: AVAILABLE,
    },
    {
      label: capitalizeFirstLetter(UNAVAILABLE),
      value: UNAVAILABLE,
    },
  ]

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const notify = (props: any) => Toast(props)

  const [csvOrderData, setCsvOrderData] = useState<any>([])
  const [isOpenCustomizeModal, setIsOpenCustomizeModal] = useState(false)
  const [tableData, setTableData] = useState<any>()
  const [stockOnHandTableColumn, setStockOnHandTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData?.skip || 0,
    take: tableData?.take || 10,
    search: '',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedStatus, setSelectedStatus] = useState<any>({
    label: 'All',
    value: 'All',
  })
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({
    label: 'All',
    value: 'All',
  })

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [warehouseList, setWarehouseList] = useState([])
  const [isLoadingCSVDownload, setIsLoadingCSVDownload] = useState(false)

  useEffect(() => {
    getWarehouseList()
  }, [])

  useEffect(() => {
    getStockOnHandList()
  }, [paginationVariables])

  const { mutate: getWarehouseListMutate } = useGetWarehouseList()

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedWarehouseList = successData.warehouses.map(
            (item: any) => {
              return {
                ...item,
                value: item?.code,
                label: item?.code,
              }
            },
          )
          setWarehouseList(updatedWarehouseList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const temp = JSON.parse(localStorage.getItem('stockOnHandTableColumn') as any)

  /** Cart Table Column Handling */
  useEffect(() => {
    if (tableData?.stockOnHands && tableData?.stockOnHands?.length > 0) {
      const columns = StockOnHandListColumn()
      if (temp.length === 0) {
        setStockOnHandTableColumn(columns)
      } else {
        const tempCol = temp?.map((item: any) => {
          const tempItem = columns?.find((col: any) => col.id === item.id)
          return {
            ...item,
            Cell: tempItem?.Cell,
          }
        })
        setStockOnHandTableColumn(tempCol)
      }
    }
  }, [tableData])

  /** API Call For stock on hand list */
  const {
    mutate: getStockOnHandListMutate,
    isLoading: getStockOnHandListIsLoading,
  } = useGetStockOnHandList()

  /** Process the get stock on hand Table Data */
  async function getStockOnHandList() {
    getStockOnHandListMutate(
      {
        take: paginationVariables?.take,
        skip: paginationVariables?.skip,
        search: paginationVariables?.search,
        status: selectedStatus?.value,
        warehouse: selectedWarehouse?.value,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setTableData(successData)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetCustomizesColumns = () => {
    setStockOnHandTableColumn(StockOnHandListColumn())
  }

  useEffect(() => {
    const columns = StockOnHandListColumn()

    const storedStockOnHandTableColumn = JSON.parse(
      localStorage.getItem('stockOnHandTableColumn') as any,
    )
    if (storedStockOnHandTableColumn === null) {
      localStorage.setItem(
        'stockOnHandTableColumn',
        JSON.stringify(StockOnHandListColumn()),
      )
    } else {
      const tempCol = storedStockOnHandTableColumn?.map((item: any) => {
        const tempItem = columns?.find((col: any) => col.id === item.id)
        return {
          ...item,
          Cell: tempItem?.Cell,
        }
      })
      setStockOnHandTableColumn(tempCol)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'stockOnHandTableColumn',
      JSON.stringify(stockOnHandTableColumn),
    )
  }, [stockOnHandTableColumn])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setPaginationVariables({
        ...paginationVariables,
        skip: 0,
        search: searchKeyWord,
      })
      setPageNumber(1)
    }
  }

  const resetFilter = () => {
    setFilterActive(false)
    setSelectedStatus({ label: 'All', value: 'All' })
    setPaginationVariables({
      ...paginationVariables,
      skip: 0,
      search: '',
    })
    setPageNumber(1)
    setIsFilterMenuOpen(false)
  }

  /** API Call export csv using react-query */
  const { mutate: stockOnHandExportAsCSVMutate } = useStockOnHandExportAsCSV()

  /** Process the export csv*/
  async function stockOnHandExportAsCSV() {
    setIsLoadingCSVDownload(true)
    stockOnHandExportAsCSVMutate(
      {
        search: paginationVariables?.search,
        status: selectedStatus?.value,
        warehouse: selectedWarehouse?.value,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCsvOrderData(successData)

          setTimeout(() => {
            csvLinkEl?.current?.link.click()
            setIsLoadingCSVDownload(false)
            notify({
              alertHeader: 'Success',
              alertBody: 'CSV file downloaded successfully',
              status: 'Success',
            })
          }, 3000)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error',
            alertBody: errData?.message
              ? errData?.message
              : 'Something went wrong',
            status: 'Error',
          })
        },
      },
    )
  }

  const onSuccessExport = () => {
    notify({
      alertHeader: 'Success',
      alertBody: 'Excel file downloaded successfully',
      status: 'Success',
    })
  }

  const onErrorExport = () => {
    notify({
      alertHeader: 'Error',
      alertBody: 'Something went wrong',
      status: 'Error',
    })
  }

  /** stock on hand list report Export excel API */
  const {
    refetch: refetchExcel,
    isRefetching: isRefetchingExcelDownload,
    isLoading: isLoadingExcelDownload,
  } = useStockOnHandReportExportAsExcel(
    {
      search: paginationVariables?.search,
      status: selectedStatus?.value === 'All' ? null : selectedStatus?.value,
      warehouse:
        selectedWarehouse?.value === 'All' ? null : selectedWarehouse?.value,
    },
    onSuccessExport,
    onErrorExport,
  )

  return (
    <>
      <div className='flex flex-col h-fit w-full bg-white rounded-lg px-3 py-6 shadow-md'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={
            'Search by Product Code, Bar Code, Product Description'
          }
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}
          handleSearchKeyPress={handleSearchKeyPress}>
          <div className='w-full flex justify-end gap-x-3 px-3 h-full'>
            <Button
              className='h-auto'
              appearance='secondary'
              iconBefore='ri-table-line text-[24px]'
              disabled={stockOnHandTableColumn?.length === 0}
              onClick={() => {
                setIsOpenCustomizeModal(true)
              }}>
              Customize Columns
            </Button>

            <Button
              className='h-auto'
              appearance='secondary'
              iconBefore='ri-file-download-line text-[24px]'
              disabled={stockOnHandTableColumn?.length === 0}
              onClick={() => {
                stockOnHandExportAsCSV()
              }}>
              Export CSV
            </Button>

            <Button
              className='h-auto'
              iconBefore='ri-file-excel-2-line text-[24px]'
              disabled={stockOnHandTableColumn?.length === 0}
              appearance='secondary'
              onClick={() => {
                refetchExcel()
              }}>
              Export Excel
            </Button>
          </div>
        </TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.5 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: 0, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex flex-col w-full pt-3 z-[10] gap-y-4'>
                <div className='flex justify-start gap-x-3'>
                  <Dropdown
                    label='By Status'
                    placeholder='All'
                    value={selectedStatus}
                    className='w-1/3'
                    onChange={(selectedItem: any) => {
                      setSelectedStatus({
                        value: selectedItem?.value,
                        label: selectedItem?.label,
                      })
                    }}
                    options={orderStatusList}
                  />

                  <Dropdown
                    label='By Warehouse'
                    placeholder='All'
                    value={selectedWarehouse}
                    className='w-1/3'
                    onChange={(selectedItem: any) => {
                      setSelectedWarehouse({
                        value: selectedItem?.value,
                        label: selectedItem?.label,
                      })
                    }}
                    options={[{ label: 'All', value: 'All' }, ...warehouseList]}
                  />
                </div>

                <div className='flex justify-start gap-x-2'>
                  <Button
                    disabled={
                      !selectedStatus?.value && !selectedWarehouse?.value
                    }
                    onClick={() => {
                      getStockOnHandList()
                      setPageNumber(1)
                      setIsFilterMenuOpen(false)

                      if (
                        selectedStatus?.value === 'All' &&
                        selectedWarehouse?.value === 'All'
                      ) {
                        setFilterActive(false)
                      } else {
                        setFilterActive(true)
                      }
                    }}>
                    Apply Filter
                  </Button>

                  <Button
                    appearance='secondary'
                    onClick={() => {
                      resetFilter()
                    }}>
                    Reset
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className='w-full h-[calc(80vh-171px)] relative pt-8'>
          <div
            className={CN(
              'styled-scroll overflow-auto w-full h-[calc(100%-58px)] bg-white',
            )}>
            <UITable
              data={tableData?.stockOnHands || []}
              isLoading={
                getStockOnHandListIsLoading ||
                isLoadingCSVDownload ||
                isRefetchingExcelDownload ||
                isLoadingExcelDownload
              }
              className=''
              columns={stockOnHandTableColumn || []}
              allowRowSelection={false}
              isSorted={true}
              isManualSortBy={false}
              hasFooter={true}
              hasCheckBox={false}
              isHeader={true}
              isFilterEnabled={false}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}
              totalRowCount={tableData?.totalCount || 0}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>
      </div>

      {/* for csv export */}
      <CSVLink
        filename='stock-on-hand-list.csv'
        data={csvOrderData}
        ref={csvLinkEl}
      />

      <CustomizeColumModal
        handleCustomizeCol={(col: any) => {
          setStockOnHandTableColumn(col)
        }}
        columns={stockOnHandTableColumn}
        handleCloseModal={() => {
          setIsOpenCustomizeModal(false)
        }}
        section='StockOnHand'
        isModalOpen={isOpenCustomizeModal}
        resetCustomizesColumns={resetCustomizesColumns}
        localStorageKey='stockOnHandTableColumn'
      />
    </>
  )
}

export default StockOnHandList
