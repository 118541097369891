import React, { FC } from 'react'
import CN from 'classnames'
import {
  ARRIVED,
  ENTERED,
  STOCKPARTRECEIVED,
  STOCKRECEIVED,
} from 'static-data/InboundShipmentStatus'

import { Badge } from 'components/atoms'
import { capitalizeAll, InboundShipmentStatusNameConversion } from 'utils'

interface InboundShipmentStatusBadgeProps {
  className?: string | undefined
  status?: string | undefined
}

export const InboundShipmentStatusBadge: FC<
  InboundShipmentStatusBadgeProps
> = ({ className, status, ...restProps }: InboundShipmentStatusBadgeProps) => {
  /** main badge styles */
  const statusBadgeClassName = CN(
    '',
    {
      '!bg-[#006CA8]': status === ENTERED,
      '!bg-[#00C9D6]': status === ARRIVED,
      '!bg-Y-600': status === STOCKPARTRECEIVED,
      '!bg-G-500': status === STOCKRECEIVED,
    },
    className,
  )

  return (
    <Badge
      className={statusBadgeClassName}
      isRounded
      type='default'
      {...restProps}>
      {InboundShipmentStatusNameConversion(capitalizeAll(status))}
    </Badge>
  )
}

export default InboundShipmentStatusBadge

InboundShipmentStatusBadge.defaultProps = {
  className: undefined,
  status: undefined,
}
