import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import successResetPassword from 'assets/animations/success-reset-password.json'
import { useLottie } from 'lottie-react'

import { Button } from 'components/atoms'

export interface SuccessResetPasswordProps {
  [x: string]: any
}

export const SuccessResetPassword: FC<SuccessResetPasswordProps> = ({
  ...restProps
}: SuccessResetPasswordProps) => {
  const navigate = useNavigate()

  const AnimatedView = () => {
    const options = {
      animationData: successResetPassword,
      loop: false,
      autoplay: true,
      className: 'w-full px-[154px] pt-8',
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <div className='flex flex-col justify-center' {...restProps}>
      <span className='flex justify-center items-center font-bold text-heading-1 text-N-700 pt-[60px]'>
        Congratulations
      </span>

      <span className='flex justify-center items-center font-medium text-base text-N-700 pt-2'>
        Your password has been changed.
      </span>

      <AnimatedView />

      <Button
        appearance='primary'
        className='mt-6 w-full'
        disabled={false}
        isLoading={false}
        onClick={() => {
          navigate('/auth/login')
        }}>
        GO TO LOGIN PAGE
      </Button>
    </div>
  )
}

SuccessResetPassword.defaultProps = {}

export default SuccessResetPassword
