/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, ReactNode, useEffect } from 'react'
import CN from 'classnames'

import { Button } from 'components/atoms'

export interface TableHeaderProps {
  children?: ReactNode
  isFilterEnable?: boolean | undefined
  searchFieldPlaceholder?: string | undefined
  [x: string]: any
}

export const TableHeader: FC<TableHeaderProps> = ({
  children,
  filterActive,
  isFilterEnable,
  isFilterMenuOpen,
  paginationVariables,
  searchFieldPlaceholder,
  searchKeyWord,
  setSearchKeyWord,
  setIsFilterMenuOpen,
  setPaginationVariables,
  handleSearchKeyPress,
}: TableHeaderProps) => {
  /** on change search */
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     searchKeyWord &&
  //       setPaginationVariables({
  //         ...paginationVariables,
  //         skip: 0,
  //         search: searchKeyWord,
  //       })
  //   }, 1000)
  //   return () => clearTimeout(timer)
  // }, [searchKeyWord])

  return (
    <>
      <div
        className={CN('flex items-center justify-between pt-[9px]', {
          'pb-[13px] border-b border-N-200': isFilterMenuOpen,
        })}>
        <div className='w-2/3 relative h-full'>
          <input
            value={searchKeyWord}
            // onKeyUp={(e: any) => {
            //   if (e.keyCode == 13) {
            //     setPaginationVariables({
            //       ...paginationVariables,
            //       search: searchKeyWord,
            //     })
            //   }

            //   if (searchKeyWord.length === 0) {
            //     setPaginationVariables({
            //       ...paginationVariables,
            //       search: searchKeyWord,
            //     })
            //   }
            // }}
            onChange={(e: any) => {
              setSearchKeyWord(e.target.value)
            }}
            onKeyPress={(e: any) => {
              handleSearchKeyPress(e)
            }}
            type='text'
            className='w-full font-regular py-[6px] pl-[32px] pr-[30px] text-N-600 rounded-sm outline outline-N-300 shadow-sm focus:outline-[2px] focus:outline-P-200 hover:bg-N-50'
            placeholder={searchFieldPlaceholder}
          />

          <i className='absolute left-[10px] top-[6px] ri-search-line text-N-400'></i>

          {searchKeyWord && (
            <i
              className='absolute right-[10px] top-[6px] ri-close-line text-N-400'
              onClick={() => {
                setSearchKeyWord('')
                setPaginationVariables({
                  ...paginationVariables,
                  search: '',
                })
              }}></i>
          )}
        </div>

        <div className='flex items-center w-full h-full'>
          <>
            {/* header optional buttons as children */}
            {children}

            {isFilterEnable && (
              <div className='relative h-full'>
                <Button
                  iconOnly
                  onClick={() => {
                    setIsFilterMenuOpen(!isFilterMenuOpen)
                  }}
                  appearance='primary'>
                  <i className='ri-filter-3-line text-[24px] leading-none' />
                </Button>

                {filterActive && (
                  <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-2px] right-[-2px]'></div>
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  )
}

TableHeader.defaultProps = {
  children: undefined,
  isFilterEnable: false,
  searchFieldPlaceholder: undefined,
}

export default TableHeader
