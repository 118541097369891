/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    isLogOutModalOpen: false,
    backFunction: null,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        resetToInitialState(state, action) {
            (state.isLoading = false),
                (state.isLogOutModalOpen = false),
                (state.backFunction = null)
        },

        setIsLoadingState(state, {payload}) {
            state.isLoading = payload
        },

        setLogoutModalShowState(state, action) {
            state.isLogOutModalOpen = true
        },

        setLogoutModalHideState(state, action) {
            state.isLogOutModalOpen = false
        },

        setBackFunctionState(state, {payload}) {
            state.backFunction = payload
        },
    },
})

export const {
    resetToInitialState,
    setIsLoadingState,
    setLogoutModalShowState,
    setLogoutModalHideState,
    setBackFunctionState,
} = layoutSlice.actions

export const setToInitial =
    (values, cb = () => {
    }) =>
        (dispatch) => {
            dispatch(resetToInitialState(values))
            return cb(values)
        }

export const setIsLoading =
    (payload, cb = () => {
    }) =>
        (dispatch) => {
            dispatch(setIsLoadingState(payload))
        }

export const setLogoutModalShow =
    (value, cb = () => {
    }) =>
        (dispatch) => {
            dispatch(setLogoutModalShowState(value))
        }

export const setLogoutModalHide =
    (value, cb = () => {
    }) =>
        (dispatch) => {
            dispatch(setLogoutModalHideState(value))
        }

export const setBackFunction =
    (payload, cb = () => {
    }) =>
        (dispatch) => {
            dispatch(setBackFunctionState(payload))
        }
export default layoutSlice.reducer
