/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPartnerOrderIntegrationsInterface {
  id: number
}

export const useGetPartnerOrderIntegrations = () => {
  const mutate = useMutation(({ id }: GetPartnerOrderIntegrationsInterface) => {
    return get(`/PartnerIntegrations`)
  })

  return mutate
}

export default useGetPartnerOrderIntegrations
