import { FC } from 'react'
import emptyState from 'assets/animations/empty-state.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

export interface EmptyStateProps {
  [x: string]: any
  className?: string | undefined
  customWidth?: string | number | undefined
}

export const EmptyState: FC<EmptyStateProps> = ({
  className,
  customWidth,
  ...restProps
}: EmptyStateProps) => {
  const EmptyStateClassNames = CN(
    'flex flex-col w-full h-full items-center justify-center absolute z-[99] left-0 top-0 bg-white',
    className,
  )

  const AnimatedView = () => {
    const options = {
      animationData: emptyState,
      loop: true,
      autoplay: true,
      speed: 5,
    }

    const { View } = useLottie(options)

    return View
  }
  return (
    <div className={EmptyStateClassNames} {...restProps}>
      <div style={{ width: customWidth }}>
        <AnimatedView />
      </div>

      <span className='flex justify-center font-semibold text-heading-3 text-N-800 pt-2'>
        No Results Found
      </span>

      <span className='font-regular text-base text-N-500 pt-2'>
        No content matched your criteria. Try searching for something else.
      </span>
    </div>
  )
}

EmptyState.defaultProps = {
  className: undefined,
  customWidth: 120,
}

export default EmptyState
