/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { useUserDetails } from 'framework/api/methods'
import { setLogoutModalHide } from 'store/reducers/layout/layoutSlice'
import { setUserDetails } from 'store/reducers/user/userSlice'

import { Button } from 'components/atoms'
import { Header } from 'components/common/Header'
import { MainSideBar } from 'components/common/Sidebar'
import { ConfirmationModal, ToastContainer } from 'components/molecules'

export interface MainLayoutProps {
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  hasSidebar,
  heading,
  isBackButton,
  children,
}: MainLayoutProps) => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()
  const token = localStorage.getItem('token')
  const { isLogOutModalOpen, backFunction }: any = useSelector<any>(
    (state) => state.layout,
  )
  const [isActiveLogoutModal, setIsActiveLogoutModal] =
    useState(isLogOutModalOpen)

  const getUserDetailsOnSuccess = (data: any) => {
    dispatch(setUserDetails(data.profile))
  }
  const getUserDetailsOnError = (error: any) => {
    localStorage.removeItem('token')
    navigate('/auth/login')
  }
  const { refetch } = useUserDetails(
    {},
    getUserDetailsOnSuccess,
    getUserDetailsOnError,
  )

  useEffect(() => {
    if (!token) {
      navigate('/auth/login')
    } else {
      refetch()
    }
    setIsActiveLogoutModal(isLogOutModalOpen)
  }, [token, isLogOutModalOpen])

  const confirmLogout = () => {
    dispatch(setLogoutModalHide())
    localStorage.clear()
    navigate('/auth/login')
  }

  const cancelLogout = () => {
    setIsActiveLogoutModal(!isActiveLogoutModal)
    dispatch(setLogoutModalHide())
  }

  const renderLayoutInner = () => {
    return (
      <div className={CN('flex flex-col h-full relative px-8 pt-4', {})}>
        {heading && (
          <div className='flex justify-between items-center h-[56px] bg-white rounded-lg px-3 py-2 shadow-md'>
            <div className={CN('flex w-full items-left flex-col', {})}>
              <p className='font-Medium text-heading-3 text-N-800 h-8 font-bold'>
                {heading}
              </p>
            </div>

            {isBackButton && (
              <div className='w-full flex justify-end items-center'>
                <Button
                  appearance='secondary'
                  iconBefore='ri-arrow-left-line text-[24px] text-s-900'
                  onClick={backFunction}>
                  {isBackButton}
                </Button>
              </div>
            )}
          </div>
        )}

        <div
          className={CN(
            'main-layout__inner flex flex-col h-fit w-full pt-6 pb-2',
          )}>
          {children}
        </div>

        {isActiveLogoutModal && (
          <ConfirmationModal
            className='w-[466px]'
            isActive={isActiveLogoutModal}
            state='error'
            errorStateIcon='ri-logout-box-line'
            title='You are about to logout'
            subTitle='Are you sure you want to proceed?'
            primaryButtonTitle='Yes, I’m Sure'
            secondaryButtonTitle='CLOSE'
            onOverlayClick={cancelLogout}
            onClickPrimaryBtn={confirmLogout}
            onClickSecondaryBtn={cancelLogout}
          />
        )}

        <ToastContainer toastClassName='mt-[62px]' />
      </div>
    )
  }

  const renderLayout = () => {
    // Render with sidebar
    if (hasSidebar) {
      return (
        <>
          <MainSideBar className='top-[0px] left-0' />
          <div className='flex flex-col w-full h-[calc(100%-72px)] overflow-y-auto styled-scroll'>
            {renderLayoutInner()}
          </div>
        </>
      )
    }

    // Render without sidebar
    return <div className='container'>{renderLayoutInner()}</div>
  }

  return (
    <>
      <Header className='fixed left-0 top-0 h-[72px]' />
      <div
        className={CN(
          'main-layout__container fixed flex top-[72px] w-full h-full bg-N-50',
        )}>
        {renderLayout()}
      </div>
    </>
  )
}

export default MainLayout
