import { FC, useEffect, useState } from 'react'
import Parcel from 'assets/images/parcel.svg'
import CN from 'classnames'

import { dateToAEST } from 'utils'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface OrderStatusProgressBarProps {
  className?: string | undefined
  currentStatus?:
    | 'Received'
    | 'Confirmed'
    | 'Picking-Progress'
    | 'Picking-Completed'
    | 'Consigned'
    | 'Partial-consigned'
    | ''
    | undefined

  [x: string]: any
}

export const OrderStatusProgressBar: FC<OrderStatusProgressBarProps> = ({
  currentStatus,
  partnerOrderHistory,
  className,
  ...restProps
}: OrderStatusProgressBarProps) => {
  const [dateList, setDateList] = useState({
    Received: '',
    Confirmed: '',
    Open: '',
    ToBeConsigned: '',
    Closed: '',
    PartialConsigned: '',
    CancelConsigned: '',
  })

  const OrderStatusProgressBarClassnames = CN(
    'border-[1px] border-N-300 rounded-lg pb-1 pt-1 w-full bg-N-50',
    className,
  )

  const containerClassnames = CN(
    'flex items-center mt-4 flex justify-center items-center w-full mb-[48px]',
  )

  useEffect(() => {
    const latestReceived = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Received' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const initialConfirmed = partnerOrderHistory?.find(
      (item: any) => capitalizeFirstLetter(item?.status) === 'Confirmed',
    )

    const latestOpen = partnerOrderHistory?.reduce((acc: any, item: any) => {
      if (
        capitalizeFirstLetter(item?.status) === 'Open' &&
        (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
      ) {
        return item
      }
      return acc
    }, null)

    const latestToBeConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Tobeconsigned' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Closed' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestPartialConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Partial-consigned' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestCancelConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          item?.status === 'CANCELLED-CONSIGNED' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    setDateList({
      Received: latestReceived
        ? dateToAEST(latestReceived?.updatedOn || '')
        : '',
      Confirmed: initialConfirmed
        ? dateToAEST(initialConfirmed?.updatedOn || '')
        : '',
      Open: latestOpen ? dateToAEST(latestOpen?.updatedOn || '') : '',
      ToBeConsigned: latestToBeConsigned
        ? dateToAEST(latestToBeConsigned?.updatedOn || '')
        : '',
      Closed: latestConsigned
        ? dateToAEST(latestConsigned?.updatedOn || '')
        : '',
      PartialConsigned: latestPartialConsigned
        ? dateToAEST(latestPartialConsigned?.updatedOn || '')
        : '',
      CancelConsigned: latestCancelConsigned
        ? dateToAEST(latestCancelConsigned?.updatedOn || '')
        : '',
    })
  }, [partnerOrderHistory])

  return (
    <div className={OrderStatusProgressBarClassnames} {...restProps}>
      <div className={containerClassnames}>
        {/* Received Section */}
        <div
          className={CN(
            'relative rounded-full flex justify-center items-center',
            {
              'w-10 h-10 bg-P-600':
                currentStatus === 'Received' ||
                currentStatus === 'Confirmed' ||
                currentStatus === 'Picking-Progress' ||
                currentStatus === 'Picking-Completed' ||
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned',
              'w-[20px] h-[20px] bg-N-300': currentStatus !== 'Received',
            },
          )}>
          {currentStatus === 'Received' && <img src={Parcel} alt='Parcel' />}

          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
              {
                'pt-10': currentStatus === 'Received',
                'pt-8': currentStatus !== 'Received',
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800': currentStatus === 'Received',
                'font-regular text-N-400':
                  currentStatus !== 'Received' &&
                  (currentStatus === undefined || currentStatus === ''),
                'font-regular text-N-700':
                  currentStatus !== 'Received' &&
                  currentStatus !== undefined &&
                  currentStatus !== '',
              })}>
              Received
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.Received}
            </div>
          </div>
        </div>

        {/* Confirmed Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-N-300': currentStatus !== 'Confirmed',
            'bg-P-600':
              currentStatus === 'Confirmed' ||
              currentStatus === 'Picking-Progress' ||
              currentStatus === 'Picking-Completed' ||
              currentStatus === 'Consigned' ||
              currentStatus === 'Partial-consigned',
          })}></div>

        <div
          className={CN(
            'relative rounded-full flex justify-center items-center',
            {
              'w-10 h-10': currentStatus === 'Confirmed',
              'w-[20px] h-[20px]': currentStatus !== 'Confirmed',
            },
            {
              'bg-P-600':
                currentStatus === 'Confirmed' ||
                currentStatus === 'Picking-Progress' ||
                currentStatus === 'Picking-Completed' ||
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned',
              'bg-N-300': currentStatus !== 'Confirmed',
            },
          )}>
          {currentStatus === 'Confirmed' && <img src={Parcel} alt='Parcel' />}

          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
              {
                'pt-11': currentStatus === 'Confirmed',
                'pt-8': currentStatus !== 'Confirmed',
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800': currentStatus === 'Confirmed',
                'font-regular text-N-400':
                  currentStatus !== 'Confirmed' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Received'),
                'font-regular text-N-700':
                  currentStatus !== 'Confirmed' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Received',
              })}>
              Confirmed
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.Confirmed}
            </div>
          </div>
        </div>

        {/* Picking Progress Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-P-600':
              currentStatus === 'Picking-Progress' ||
              currentStatus === 'Picking-Completed' ||
              currentStatus === 'Consigned' ||
              currentStatus === 'Partial-consigned',
            'bg-N-300': currentStatus !== 'Picking-Progress',
          })}></div>

        <div
          className={CN(
            'relative rounded-full bg-N-300 flex justify-center items-center',
            {
              'w-10 h-10': currentStatus === 'Picking-Progress',
              'w-[20px] h-[20px]': currentStatus !== 'Picking-Progress',
            },
            {
              'bg-P-600':
                currentStatus === 'Picking-Progress' ||
                currentStatus === 'Picking-Completed' ||
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned',
              'bg-N-300': currentStatus !== 'Confirmed',
            },
          )}>
          {currentStatus === 'Picking-Progress' && (
            <img src={Parcel} alt='Parcel' />
          )}
          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
              {
                'pt-11': currentStatus === 'Picking-Progress',
                'pt-8': currentStatus !== 'Picking-Progress',
              },
            )}>
            <div
              className={CN('text-Small', {
                'font-bold text-N-800': currentStatus === 'Picking-Progress',
                'font-regular text-N-400':
                  currentStatus !== 'Picking-Progress' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Received' ||
                    currentStatus === 'Confirmed'),
                'font-regular text-N-700':
                  currentStatus !== 'Picking-Progress' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Received' &&
                  currentStatus !== 'Confirmed',
              })}>
              Picking Progress
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.Open}
            </div>
          </div>
        </div>

        {/* Picking Completed Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-P-600':
              currentStatus === 'Picking-Completed' ||
              currentStatus === 'Consigned' ||
              currentStatus === 'Partial-consigned',
            'bg-N-300': currentStatus !== 'Picking-Completed',
          })}></div>

        <div
          className={CN(
            'rounded-full relative bg-N-300 flex justify-center items-center',
            {
              'w-10 h-10': currentStatus === 'Picking-Completed',
              'w-[20px] h-[20px]': currentStatus !== 'Picking-Completed',
            },
            {
              'bg-P-600':
                currentStatus === 'Picking-Completed' ||
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned',
              'bg-N-300': currentStatus !== 'Confirmed',
            },
          )}>
          {currentStatus === 'Picking-Completed' && (
            <img src={Parcel} alt='Parcel' />
          )}
          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
              {
                'pt-11': currentStatus === 'Picking-Completed',
                'pt-8': currentStatus !== 'Picking-Completed',
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800': currentStatus === 'Picking-Completed',
                'font-regular text-N-400':
                  currentStatus !== 'Picking-Completed' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Received' ||
                    currentStatus === 'Confirmed' ||
                    currentStatus === 'Picking-Progress'),
                'font-regular text-N-700':
                  currentStatus !== 'Picking-Completed' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Received' &&
                  currentStatus !== 'Confirmed' &&
                  currentStatus !== 'Picking-Progress',
              })}>
              Picking Completed
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {dateList?.ToBeConsigned}
            </div>
          </div>
        </div>

        {/* Consigned Section */}
        <div
          className={CN('h-[5px] w-[20.5%]', {
            'bg-P-600':
              currentStatus === 'Consigned' ||
              currentStatus === 'Partial-consigned',
            'bg-N-300': !(
              currentStatus == 'Consigned' ||
              currentStatus == 'Partial-consigned'
            ),
          })}></div>

        <div
          className={CN(
            'rounded-full relative bg-N-300 flex justify-center items-center',
            {
              'w-10 h-10':
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned',
              'w-[20px] h-[20px]': !(
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned'
              ),
            },
            {
              'bg-P-600':
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned',
              'bg-N-300': !(
                currentStatus === 'Consigned' ||
                currentStatus === 'Partial-consigned'
              ),
            },
          )}>
          {(currentStatus === 'Consigned' ||
            currentStatus === 'Partial-consigned') && (
            <img src={Parcel} alt='Parcel' />
          )}

          <div
            className={CN(
              'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
              {
                'pt-11':
                  currentStatus === 'Consigned' ||
                  currentStatus === 'Partial-consigned',
                'pt-8': !(
                  currentStatus === 'Consigned' ||
                  currentStatus === 'Partial-consigned'
                ),
              },
            )}>
            <div
              className={CN('text-small', {
                'font-bold text-N-800':
                  currentStatus === 'Consigned' ||
                  currentStatus === 'Partial-consigned',
                'font-regular text-N-400':
                  currentStatus !== 'Consigned' &&
                  currentStatus !== 'Partial-consigned' &&
                  (currentStatus === undefined ||
                    currentStatus === '' ||
                    currentStatus === 'Received' ||
                    currentStatus === 'Confirmed' ||
                    currentStatus === 'Picking-Progress' ||
                    currentStatus === 'Picking-Completed'),
                'font-regular text-N-700':
                  currentStatus !== 'Consigned' &&
                  currentStatus !== 'Partial-consigned' &&
                  currentStatus !== undefined &&
                  currentStatus !== '' &&
                  currentStatus !== 'Received' &&
                  currentStatus !== 'Confirmed' &&
                  currentStatus !== 'Picking-Progress' &&
                  currentStatus !== 'Picking-Completed',
              })}>
              {currentStatus == 'Partial-consigned'
                ? 'Partial Consigned'
                : 'Closed'}
            </div>

            <div className='text-x-small font-regular text-N-500'>
              {currentStatus == 'Consigned' && dateList?.Closed}
              {currentStatus == 'Partial-consigned' &&
                dateList?.PartialConsigned}
              {currentStatus == 'Picking-Completed' &&
                dateList?.CancelConsigned}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OrderStatusProgressBar.defaultProps = {
  className: undefined,
  currentStatus: undefined,
}

export default OrderStatusProgressBar
