/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getPartnerOrderDetailsById = async (reqData: any) => {
  const data = reqData.queryKey[1]
  if (data.orderId !== '') {
    const res = await get(`/PartnerOrders/${data.orderId}`)
    return res.data
  } else {
    return null
  }
}

export const useGetPartnerOrderDetailsById = (
  { ...reqData },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['userData', reqData], getPartnerOrderDetailsById, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useGetPartnerOrderDetailsById
