/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { ICSWMS } from 'static-data/courierList'

import { OrderStatusBadge } from 'components/molecules'
import { courierNameConversion, dateAndTimeToAEST } from 'utils'

export const OrderListColumn = (navigate: any) => {
  const COLUMNS = [
    {
      id: 'id',
      Header: '',
      accessor: 'id',
      forSorting: '',
      isSortable: false,
      hasFilter: false,
      isFixedSelect: false,
      className: 'min-w-[90px]',
      headerClassName: '!w-[90px]',
      commonClassName: '!w-[90px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start'>
          <i
            className='hover:cursor-pointer ri-eye-line'
            onClick={() => {
              navigate(`/order-details?order_id=${row.value}`)
            }}
          />
        </div>
      ),
    },
    {
      id: 'orderNo',
      Header: 'WAREHOUSE ORDER NO',
      accessor: 'orderNo',
      forSorting: 'orderNo',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'customerOrderNo',
      Header: 'CUSTOMER ORDER NO',
      accessor: 'customerOrderNo',
      forSorting: 'customerOrderNo',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: false,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>{row.value}</span>
        </div>
      ),
    },
    {
      id: 'createdOn',
      Header: 'DATE',
      accessor: 'createdOn',
      forSorting: 'createdOn',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          <span className='flex  break-words line-clamp-1'>
            {row.value ? dateAndTimeToAEST(row.value) : ''}
          </span>
        </div>
      ),
    },
    {
      id: 'status',
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[150px]'>
          {row.value && <OrderStatusBadge orderStatus={row.value} />}
        </div>
      ),
    },
    {
      id: 'integrationType',
      Header: 'INTEGRATION',
      accessor: 'integrationType',
      forSorting: 'integrationType',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          {row.value === ICSWMS || row.value === 'PaperlessWMS'
            ? 'Manual'
            : row.value}
        </div>
      ),
    },
    {
      id: 'warehouseName',
      Header: 'WAREHOUSE',
      accessor: 'warehouseName',
      forSorting: 'warehouseName',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[280px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[280px]'>
          {row.value}
        </div>
      ),
    },
    {
      id: 'customerFirstName',
      Header: 'RECEIVER',
      accessor: 'customerFirstName',
      forSorting: 'customerFirstName',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[220px]',
      enableVisible: true,
      isVisible: true,
      Cell: ({ row }: any) => (
        <div className='flex items-center justify-start min-w-[220px]'>
          {row.original.customerFirstName && row.original.customerFirstName}{' '}
          {row.original.customerLastName && row.original.customerLastName}
        </div>
      ),
    },
    {
      id: 'shippingAddress.state',
      Header: 'RECEIVER STATE',
      accessor: 'shippingAddress.state',
      forSorting: 'shippingAddress.state',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[150px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[150px]'>
          {row.value}
        </div>
      ),
    },
    {
      id: 'courier',
      Header: 'COURIER',
      accessor: 'courier',
      forSorting: 'courier',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[150px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[150px]'>
          {courierNameConversion(row.value)}
        </div>
      ),
    },
    {
      id: 'trackingNo',
      Header: 'TRACKING NO',
      accessor: 'trackingNo',
      forSorting: 'trackingNo',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[180px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[180px]'>
          {row.value}
        </div>
      ),
    },
    {
      id: 'shippingAddress.country',
      Header: 'COUNTRY',
      accessor: 'shippingAddress.country',
      forSorting: 'shippingAddress.country',
      isSortable: true,
      hasFilter: true,
      isFixedSelect: false,
      className: 'min-w-[280px]',
      enableVisible: true,
      isVisible: true,
      Cell: (row: any) => (
        <div className='flex items-center justify-start min-w-[150px]'>
          {row.value}
        </div>
      ),
    },
  ]

  return COLUMNS
}

export default OrderListColumn
