import { FC, ReactNode } from 'react'
import Select from 'react-select'
import CN from 'classnames'

interface DropdownProps {
  className?: string | undefined
  defaultValue?: any | undefined
  helperText?: ReactNode | string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isErrored?: boolean
  isRequired?: boolean
  label?: string | undefined
  onBlur?: (e: any) => void | undefined
  onChange?: (e: any) => void | undefined
  options?: any
  placeholder?: string | undefined
  value?: any | undefined
  [x: string]: any
}

export const Dropdown: FC<DropdownProps> = ({
  className,
  defaultValue,
  helperText,
  isCustomStyles,
  isErrored,
  isDisabled,
  isRequired,
  label,
  onBlur,
  onChange,
  options,
  placeholder,
  value,
  ...restProps
}: DropdownProps) => {
  /** simple select main class name */
  const simpleSelectMainClassName = !isCustomStyles
    ? CN('main-container flex flex-col', className)
    : className

  /** helper text style */
  const helperTextClassName = CN('text-small font-regular pt-1', {
    'text-N-500': !isErrored,
    'text-R-500': isErrored,
  })
  /** select input class name */
  const inputSelectStyles = {
    control: (styles: any, { isFocused, menuIsOpen }: any) => ({
      ...styles,
      'height': 40,
      'borderRadius': 2,
      'border': '0px',
      'fontSize': '14px',
      'backgroundColor': isDisabled && '#EAECF0',
      'color': isDisabled ? '#D0D5DD' : '#344054',
      'outline': menuIsOpen
        ? '2px solid #FCD7AC'
        : isErrored
          ? '2px solid #F03D00'
          : isFocused
            ? '2px solid #FCD7AC'
            : '1px solid #D0D5DD',
      '&:hover': {
        outline: '2px solid #FCD7AC !important',
      },
    }),
    option: (styles: any, { isSelected }: any) => {
      return {
        ...styles,
        'backgroundColor': isSelected ? '#F37A26 !important' : null,
        'color': isSelected ? 'white !important' : '#344054',
        '&:hover': {
          backgroundColor: '#FEF7EE',
          color: isSelected ? 'white !important' : '#F37A26',
        },
      }
    },
  }

  return (
    <div className={simpleSelectMainClassName} {...restProps}>
      {/* label */}
      {label !== undefined && (
        <div className='label-container text-small pb-1'>
          <div className='label text-base flex text-N-800 font-medium'>
            {/* label text */}
            <span>{label}</span>

            {/* required */}
            {isRequired && <span className='text-R-500 pl-[2px]'>*</span>}
          </div>
        </div>
      )}

      {/* select fields */}
      <Select
        styles={inputSelectStyles}
        options={options}
        className='sd-classes-select'
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        {...restProps}
      />

      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

export default Dropdown

Dropdown.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  description: undefined,
  helperText: undefined,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isRequired: false,
  label: undefined,
  onBlur: undefined,
  onChange: undefined,
  options: undefined,
  placeholder: 'select...',
  value: undefined,
}
