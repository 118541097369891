/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCountryListInterface {
  id: number
}

export const useGetCountryList = () => {
  const mutate = useMutation(({ id }: GetCountryListInterface) => {
    return get(`/Country`)
  })

  return mutate
}

export default useGetCountryList
