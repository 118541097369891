/** Users */
import { combineReducers } from 'redux'
import layoutReducer from 'store/reducers/layout/layoutSlice'
import userReducer from 'store/reducers/user/userSlice'

import mainSideMenuReducer from './side-menu/mainSideMenuReducer'

const appReducer = combineReducers({
  sideMenuList: mainSideMenuReducer,
  user: userReducer,
  layout: layoutReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}
