/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import CN from 'classnames'
import {setCurrentRoute} from 'store/reducers/side-menu/mainSideMenuReducer'

import {MyProfile} from './MyProfile'
import {Partner} from "./Partner";
import {Security} from './Security'

export const Profile: FC = () => {
    const dispatch: any = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const tabs = [
        {
            id: 1,
            title: 'My Profile',
            url: 'my-profile',
            content: <MyProfile/>,
        },
        {
            id: 2,
            title: 'Partner',
            url: 'partner',
            content: <Partner/>,
        },
        {
            id: 3,
            title: 'Security',
            url: 'security',
            content: <Security/>,
        },
    ]

    const [tab, setTab] = useState(1)
    const paramValue = searchParams.get('tab')

    useEffect(() => {
        if (paramValue == 'security') {
            setTab(3)
        } else if (paramValue == 'information') {
            setTab(2)
        } else if (paramValue == 'my-profile') {
            setTab(1)
        }
    }, [paramValue])

    return (
        <div className='flex h-full w-full gap-5 pb-2'>
            {' '}
            <div className='flex flex-col gap-y-3 w-3/12 bg-white rounded-lg shadow-md p-3'>
                {tabs?.map((item) => (
                    <div
                        key={item.id}
                        className={CN(' w-full rounded-lg p-3 h-fit cursor-pointer', {
                            'bg-white': tab !== item.id,
                            'bg-P-100 text-P-500 font-bold': tab == item.id,
                        })}
                        onClick={() => {
                            setTab(item.id)
                            window.history.pushState({}, '', `/profile?tab=${item.url}`)
                            dispatch(setCurrentRoute(`/profile?tab=${item.url}`))
                        }}>
                        {item.title}
                    </div>
                ))}
            </div>
            <div className='flex h-full w-full bg-white rounded-lg shadow-md p-3'>
                {tabs?.map((item) => (
                    <div
                        key={item.id}
                        className={CN('flex w-full', {hidden: tab !== item.id})}>
                        {item.content}
                    </div>
                ))}
            </div>
        </div>
    )
}

Profile.defaultProps = {
    className: undefined,
}

export default Profile
